import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./Home";
import Agua from "./Agua";
import Esgoto from "./Esgoto";
import Bep from "./Bep";
import ProjectDetails from "./Bep/ProjectDetails"; // Importar o componente ProjectDetails
import ArquivoTecnico from "./ArquivoTecnico";
import Padronizacoes from "./Padronizacoes";
import Signos from "./Signos";
import Administracao from "./Administracao";
import Form from "../modules/sat-migration/components/templates/Form"; //Módulo migração de metadados
import ProjectSearchAndDetails from "./Bep/ProjectSearchAndDetails";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="administracao" element={<Administracao />} />
      <Route path="agua" element={<Agua />} />
      <Route path="esgoto" element={<Esgoto />} />
      <Route path="bep" element={<Bep />} />
      <Route path="bep/:id" element={<ProjectDetails />} />
      <Route path="arquivoTecnico" element={<ArquivoTecnico />} />
      <Route path="tecbim" element={<Padronizacoes />} />
      <Route path="signos" element={<Signos />} />
      <Route path="migracao" element={<Form />} />
      <Route path="/projects/search" element={<ProjectSearchAndDetails />} />
      <Route path="/configurar-membros" element={<ProjectSearchAndDetails />} />
    </Routes>
  );
};

export default Router;

import { Alert, Card, Snackbar, Tooltip, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ButtonPage from "./ButtonPage";
import WaterLight from "../../assets/images/home/water-light.png";
import WaterDark from "../../assets/images/home/water-dark.png";
import SewageLight from "../../assets/images/home/sewage-light.png";
import SewageDark from "../../assets/images/home/sewage-dark.png";
import TechnicalCollectionDark from "../../assets/images/home/technical_collection-dark.png";
import TechnicalCollectionLight from "../../assets/images/home/technical_collection-light.png";
import StandardizationDark from "../../assets/images/home/standardization-dark.png";
import StandardizationLight from "../../assets/images/home/standardization-light.png";
import BookletSignosDark from "../../assets/images/home/booklet_signos-dark.png";
import BookletSignosLight from "../../assets/images/home/booklet_signos-light.png";
import ProjetoDark from "../../assets/images/home/projeto-dark.png";
import ProjetoLight from "../../assets/images/home/projeto-light.png";
import GloboDark from "../../assets/images/home/globo-dark.png";
import GloboLight from "../../assets/images/home/globo-light.png";
import BibliotecaDark from "../../assets/images/home/biblioteca-dark.png";
import BibliotecaLight from "../../assets/images/home/biblioteca-light.png";
import { useContext } from "react";
import { AlertContext } from "../../Provider/AlertProvider";
import {
  AecFolderContext,
  AecFolderProvider,
} from "../../Provider/AecFolderProvider";
import { ProjectContext } from "../../Provider/ProjectProvider";
import { AuthContext } from "../../Provider/AuthProvider";

const cardContainer = {
  borderRadius: "8px",
  padding: "24px",
  margin: "24px 48px",
};

const listPagesSectionStyle = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-evenly",
  margin: "64px 16px 16px",
  gap: 20,
};

const ListPages = () => {
  const { Projects } = useContext(ProjectContext);
  const { projectsPermissions } = useContext(AecFolderContext);
  const { errorMessage, showErrorAlert, displayErrorAlert } =
    useContext(AlertContext);
  const { verifyToken } = useContext(AuthContext);

  let navigate = useNavigate();
  const theme = useTheme();

  const goToAgua = () => {
    verifyToken();

    if (!projectsPermissions.includes(Projects.AGUA)) {
      displayErrorAlert(
        "Você não possui permissão para acessar o projeto selecionado."
      );
    } else {
      let path = `/agua`;
      navigate(path);
    }
  };

  const goToEsgoto = () => {
    verifyToken();

    if (!projectsPermissions.includes(Projects.ESGOTO)) {
      displayErrorAlert(
        "Você não possui permissão para acessar o projeto selecionado."
      );
    } else {
      let path = `/esgoto`;
      navigate(path);
    }
  };

  const goToArquivoTecnico = () => {
    verifyToken();

    if (!projectsPermissions.includes(Projects.ARQUIVO)) {
      displayErrorAlert(
        "Você não possui permissão para acessar o projeto selecionado."
      );
    } else {
      let path = `/arquivoTecnico`;
      navigate(path);
    }
  };

  const goToTecBIM = () => {
    verifyToken();
    if (!projectsPermissions.includes(Projects.BEP)) {
      displayErrorAlert("Em desenvolvimento");
    }
  };

  const goToSignos = () => {
    verifyToken();

    if (!projectsPermissions.includes(Projects.SIGNOS)) {
      displayErrorAlert(
        "Você não possui permissão para acessar o projeto selecionado."
      );
    } else {
      // Use apenas um link
      const url =
        "https://acc.autodesk.com/docs/files/projects/f72fd221-7c8b-44c7-9ff0-6e941fe3cfd3?folderUrn=urn%3Aadsk.wipprod%3Afs.folder%3Aco.32onf_RDR_6uNgoyj__NAw&viewModel=detail&moduleId=folders";

      // Abrir o link em uma nova aba
      window.open(url, "_blank");
    }
  };

  const goToGeoBIM = () => {
    verifyToken();
    if (!projectsPermissions.includes(Projects.GEOBIM)) {
      displayErrorAlert("Em desenvolvimento");
    }
  };

 
  const goToBEP = () => {
    let path = `/bep`;
    navigate(path);
};



  const goToBiblioteca = () => {
    verifyToken();

    if (!projectsPermissions.includes(Projects.TECBIM)) {
      displayErrorAlert(
        "Você não possui permissão para acessar a biblioteca selecionada."
      );
    } else {
      const url =
        "https://acc.autodesk.com/docs/share/projects/1804abc4-b91b-4296-b200-f2945d8028b3/files?shareGroupId=946e9899-447d-4dba-b11c-6a788fa4c78d";

      // Abrir o link em uma nova aba
      window.open(url, "_blank");
    }
  };

  return (
    <main>
      <Card style={cardContainer}>
        <section style={listPagesSectionStyle}>
          <ButtonPage
            onClick={goToAgua}
            image={theme.palette.mode === "dark" ? WaterLight : WaterDark}
            text={"Água"}
            color={"#4287f5"}
            tooltip="Sistema Produtor de Água"
          />
          <ButtonPage
            onClick={goToEsgoto}
            image={theme.palette.mode === "dark" ? SewageLight : SewageDark}
            text={"Esgoto"}
            color={"#F4B942"}
            tooltip="Sistema de Esgotamento Sanitário"
          />
          <ButtonPage
            onClick={goToArquivoTecnico}
            image={
              theme.palette.mode === "dark"
                ? TechnicalCollectionLight
                : TechnicalCollectionDark
            }
            text={"Arquivo Técnico"}
            tooltip={"SAT"}
            color={"#06d6a0"}
          />
          <ButtonPage
            onClick={goToTecBIM}
            image={
              theme.palette.mode === "dark"
                ? StandardizationLight
                : StandardizationDark
            }
            text={"TecBIM"}
            color={"#b5a2cd"}
            tooltip="Gestão da Biblioteca BIM Sabesp"
          />
          <ButtonPage
            onClick={goToSignos}
            image={
              theme.palette.mode === "dark"
                ? BookletSignosLight
                : BookletSignosDark
            }
            text={"Documentos SIGNOS"}
            color={"#f45b68"}
            tooltip="Documentos externos do SIGNOS"
          />
        </section>
      </Card>
      <Card style={cardContainer}>
        <section style={listPagesSectionStyle}>
          <ButtonPage
            onClick={goToGeoBIM}
            image={theme.palette.mode === "dark" ? GloboLight : GloboDark}
            text={"GeoBIM"}
            color={"#705714"}
            tooltip="Mapa Integrado SIGNOS x BIM"
          />
          <ButtonPage
            onClick={goToBEP}
            image={theme.palette.mode === "dark" ? ProjetoLight : ProjetoDark}
            text={"BEP"}
            color={"#7fff7f"}
            tooltip="Automação do Plano de Execução BIM"
          />
          <ButtonPage
            onClick={goToBiblioteca}
            image={
              theme.palette.mode === "dark" ? BibliotecaLight : BibliotecaDark
            }
            text={"Biblioteca BIM SABESP"}
            color={"#FF1493"}
            tooltip="Link para download da Biblioteca BIM Sabesp"
          />
        </section>
      </Card>
      <Snackbar
        open={showErrorAlert}
        sx={{ height: "100%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </main>
  );
};

export default ListPages;

import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  IconButton,
  Select,
  CircularProgress 
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createProject } from "../../../service/api";
import { AlertContext } from "../../../Provider/AlertProvider";
import "dayjs/locale/pt-br";
import JustificativaModal from "../JustificativaModal"; 
import apiRequest from "../../../service/apiRequest"; 
import Autocomplete from "@mui/material/Autocomplete";

const FormBep = ({ onSave, onCancel, userEmail }) => {
  const { displaySuccessAlert, displayErrorAlert } = useContext(AlertContext);
  const [formData, setFormData] = useState({
    nomeProjeto: "",
    numeroProjeto: "",
    type: "",
    tipo_projeto: "", 
    data_inicio: null,
    data_fim: null,
    endereco: "",
    cidade: "",
    estado: "",
    cep: "",
    valor_do_projeto: "",
    admin_email: "" 
  });

  const [adminEmails, setAdminEmails] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [loadingAdmins, setLoadingAdmins] = useState(false); 
  const [showJustificativaModal, setShowJustificativaModal] = useState(false);
  const [justificativa, setJustificativa] = useState("");

  
  const fieldLabels = {
    nomeProjeto: "Nome do Projeto",
    numeroProjeto: "Número do Projeto",
    type: "Infraestrutura",
    tipo_projeto: "Tipo de Projeto", 
    data_inicio: "Data de Início",
    data_fim: "Data de Término",
    cidade: "Cidade",
    estado: "Estado",
    cep: "CEP",
    valor_do_projeto: "Valor do Projeto",
    admin_email: "E-mail do Administrador" 
  };

  // Buscar lista de administradores ao carregar o formulário
const fetchAdminEmails = async () => {
  try {
    setLoadingAdmins(true);
    const response = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/list-project_admin`);
    //console.log("📌 Dados retornados de /list/hub_users:", response.data);

    if (response.data && Array.isArray(response.data.admin_emails)) {
    setAdminEmails(response.data.admin_emails.map((email) => ({ email })));
    
    } else {
      console.warn("⚠️ Formato inesperado da resposta da API:", response.data);
      setAdminEmails([]);
    }
  } catch (error) {
    console.error("❌ Erro ao buscar administradores:", error);
    setAdminEmails([]);
  } finally {
    setLoadingAdmins(false);
  }
};


  useEffect(() => {
    fetchAdminEmails();
  }, [displayErrorAlert]);

  const handleAdminClick = () => {
    if (adminEmails.length === 0) {
      fetchAdminEmails();
    }
  };

  const handleDateChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveData = async () => {
    setLoading(true);

    // Pequena pausa para garantir que o estado da justificativa é atualizado
    await new Promise((resolve) => setTimeout(resolve, 100));

    console.log("Justificativa antes da verificação:", justificativa);

    // Verifica se a data de término não é inferior à data de início
    if (formData.data_inicio && formData.data_fim) {
        if (formData.data_fim.isBefore(formData.data_inicio, "day")) {
            displayErrorAlert("A data de término não pode ser anterior à data de início.");
            setLoading(false);
            return;
        }
    }

    // Verifica se os campos obrigatórios estão preenchidos
    const requiredFields = {
        nomeProjeto: "Nome do Projeto",
        numeroProjeto: "Número do Projeto",
        type: "Infraestrutura",
        tipo_projeto: "Tipo de Projeto",
        data_inicio: "Data de Início",
        data_fim: "Data de Término",
        cidade: "Cidade",
        estado: "Estado",
        valor_do_projeto: "Valor do Projeto",
        admin_email: "E-mail do Administrador"
    };

    for (const [key, label] of Object.entries(requiredFields)) {
        if (!formData[key] || formData[key].toString().trim() === "") {
            displayErrorAlert(`O campo "${label}" é obrigatório.`);
            setLoading(false);
            return;
        }
    }

    // Validar justificativa separadamente
    if (!justificativa || justificativa.trim() === "") {
        displayErrorAlert("A justificativa é obrigatória.");
        setLoading(false);
        return;
    }

    console.log("Justificativa antes do envio:", justificativa);

    const projectData = {
        nomeProjeto: formData.nomeProjeto,
        numeroProjeto: formData.numeroProjeto,
        type: formData.type,
        tipo_projeto: formData.tipo_projeto,
        data_inicio: formData.data_inicio ? formData.data_inicio.format("YYYY-MM-DD") : null,
        data_fim: formData.data_fim ? formData.data_fim.format("YYYY-MM-DD") : null,
        endereco: formData.endereco,
        cidade: formData.cidade,
        estado: formData.estado,
        cep: formData.cep,
        valor_do_projeto: parseFloat(formData.valor_do_projeto.replace(/[^0-9,]/g, "").replace(",", ".")),
        justificativa: justificativa,
        userEmail: userEmail,
        admin_email: formData.admin_email
    };

    console.log("Enviando dados para API:", projectData);

    try {
        const response = await createProject(projectData);

        if (response.status === 201) {
            displaySuccessAlert("Projeto enviado para aprovação!", 10000);
            onSave();
        } else {
            throw new Error(response.data?.message || "Erro desconhecido.");
        }
    } catch (error) {
        console.error("Erro ao salvar o projeto:", error);
        displayErrorAlert(error.message || "Erro ao salvar o projeto.");
    } finally {
        setLoading(false);
    }
};


const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "valor_do_projeto") {
        // Remove qualquer caractere que não seja número ou ponto decimal
        let numericValue = value.replace(/\D/g, "");

        // Converte para número e aplica a formatação brasileira
        let formattedValue = (Number(numericValue) / 100).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });

        setFormData({ ...formData, [name]: formattedValue });
    } else {
        setFormData({ ...formData, [name]: value });
    }
};

// Limpa todos os campos do formulário
const handleClearForm = () => {
  setFormData({
    nomeProjeto: "",
    numeroProjeto: "",
    type: "",
    tipo_projeto: "",
    data_inicio: null,
    data_fim: null,
    endereco: "",
    cidade: "",
    estado: "",
    cep: "",
    valor_do_projeto: "",
    admin_email: "" 
  });
};

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Dialog 
          open={true} 
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              onCancel();
            }
          }} 
          maxWidth="sm" 
          fullWidth
        >
        <Grid container justifyContent="space-between" alignItems="center" style={{ padding: "16px" }}>
          <Typography variant="h6">Criar Projeto</Typography>
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Grid>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nome do Projeto"
                name="nomeProjeto"
                value={formData.nomeProjeto}
                onChange={handleChange}
                fullWidth
                required
                //error={!formData.nomeProjeto.trim()} // Adiciona erro se estiver vazio
                helperText={!formData.nomeProjeto.trim() ? "Este campo é obrigatório." : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Número do Projeto"
                name="numeroProjeto"
                value={formData.numeroProjeto}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Infraestrutura"
                name="type"
                value={formData.type}
                onChange={handleChange}
                fullWidth
                required
              >
                <MenuItem value="Suprimento de água">Suprimento de água</MenuItem>
                <MenuItem value="Águas residuais/esgoto">
                  Águas residuais/esgoto
                </MenuItem>
                <MenuItem value="Serviços de utilidade pública">Serviços de utilidade pública</MenuItem>
                
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Tipo de Projeto"
                name="tipo_projeto"
                value={formData.tipo_projeto}
                onChange={handleChange}
                fullWidth
                required
              >
                <MenuItem value="Crescimento Vegetativo">Crescimento Vegetativo</MenuItem>
                <MenuItem value="CT">CT</MenuItem>
                <MenuItem value="Eimob - BASE">Eimob - BASE</MenuItem>
                <MenuItem value="ID PPI_EPM - Expansão (água)">ID PPI_EPM - Expansão (água)</MenuItem>
                <MenuItem value="ID PPI_EPM - Renovação (esgoto)">ID PPI_EPM - Renovação (esgoto)</MenuItem>
                <MenuItem value="ID PPI_EPR">ID PPI_EPR</MenuItem>
                <MenuItem value="Imobilizados">Imobilizados</MenuItem>
                <MenuItem value="Obras_Modelo_PNM">Obras_Modelo_PNM</MenuItem>
                <MenuItem value="EMQ - Modelo">EMQ - Modelo</MenuItem>
                <MenuItem value="EPP - Concepção">EPP - Concepção</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Endereço"
                name="endereco"
                value={formData.endereco}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Cidade"
                name="cidade"
                value={formData.cidade}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Estado"
                name="estado"
                value={formData.estado}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="CEP"
                name="cep"
                value={formData.cep}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Data de Início *"
                value={formData.data_inicio}
                onChange={(value) => handleDateChange("data_inicio", value)}
                format="DD/MM/YYYY"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Data de Término *"
                value={formData.data_fim}
                onChange={(value) => handleDateChange("data_fim", value)}
                format="DD/MM/YYYY"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Valor do Projeto (BRL)"
                name="valor_do_projeto"
                value={formData.valor_do_projeto}
                onChange={handleChange}
                fullWidth
                type="text"
                required
                inputProps={{
                    maxLength: 21 // Limita o número de caracteres para não ultrapassar o banco
                }}
              />
            </Grid>
            <Grid item xs={12}>
            {!adminEmails.length && !loadingAdmins && (
              <Button
                variant="outlined"
                fullWidth
                onClick={fetchAdminEmails}
              >
                Carregar Administradores
              </Button>
            )}

            {loadingAdmins && (
              <Button
                variant="outlined"
                fullWidth
                disabled
                startIcon={<CircularProgress size={20} />}
              >
                Carregando administradores de projeto...
              </Button>
            )}

            {!loadingAdmins && adminEmails.length > 0 && (
              <Autocomplete
                options={adminEmails}
                getOptionLabel={(option) => option.email}
                value={adminEmails.find((user) => user.email === formData.admin_email) || null}
                onChange={(event, newValue) => {
                  setFormData({ ...formData, admin_email: newValue ? newValue.email : "" });
                }}
                inputValue={formData.admin_email}
                onInputChange={(event, newInputValue) => {
                  setFormData({ ...formData, admin_email: newInputValue });
                }}
                filterOptions={(options, state) =>
                  options.filter((option) =>
                    option.email.toLowerCase().includes(state.inputValue.toLowerCase())
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Administrador de Projeto"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>



            <Grid item xs={12}>
              <Button 
                variant="contained" 
                color="primary" 
                fullWidth
                onClick={() => setShowJustificativaModal(true)}
                required 
              >
                Adicionar Justificativa
              </Button>
            </Grid>
          </Grid>

          <Typography variant="body2" color="textSecondary" style={{ marginTop: "10px", fontStyle: "italic" }}>
            * Campos obrigatórios
          </Typography>
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          <Button variant="outlined" color="secondary" onClick={handleClearForm}>
            Limpar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveData}
            disabled={loading}
          >
            {loading ? "Enviando..." : "Criar"}
          </Button>
        </DialogActions>
      </Dialog>
      <JustificativaModal 
        open={showJustificativaModal}
        onClose={() => setShowJustificativaModal(false)}
        onSave={(text) => {
            console.log("Texto da justificativa salvo:", text); 
            setJustificativa(text.trim()); 
        }}
      />
    </LocalizationProvider>
  );
};

export default FormBep;
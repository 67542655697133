import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Backdrop,
  Dialog, 
  DialogContent,
  DialogActions, 
  Autocomplete 
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import "rsuite/dist/rsuite.min.css";
import { ptBR } from "date-fns/locale";
import { DataGrid } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { format, parse } from "date-fns"; 
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers'; 
import dayjs from "dayjs"; // Add dayjs import

import Header from "../../components/Header";
import Footer from "../../containers/footer";
import FormSidebar from "../../components/Form/Sidebar";
import FormBep from "../../components/Form/Bep";
import JustificativaModal from "../../components/Form/JustificativaModal";
import MotivoRejeicaoModal from "../../components/Form/MotivoRejeicaoModal";
import { getTokenAuthenticate } from "../../service/AuthService";
import apiRequest from "../../service/apiRequest"; 
import { AlertContext } from "../../Provider/AlertProvider"; 
import { AecFolderContext } from "../../Provider/AecFolderProvider"; 
import ApproverManagement from "./ApproverManagement"; 

const Bep = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { displaySuccessAlert, displayErrorAlert, showSuccessAlert, successMessage, showErrorAlert, errorMessage } = useContext(AlertContext); // Use context to display alerts
  const { userData } = useContext(AecFolderContext);

  const [isApproverFieldVisible, setIsApproverFieldVisible] = useState(false);
  const [approverEmail, setApproverEmail] = useState("");
  const [showJustificativaModal, setShowJustificativaModal] = useState(false);
  const [showMotivoRejeicaoModal, setShowMotivoRejeicaoModal] = useState(false);
  const [justificativa, setJustificativa] = useState("");
  const [motivoRejeicao, setMotivoRejeicao] = useState("");
  const [loadingAction, setLoadingAction] = useState(false);
  const [startDate, setStartDate] = useState(null); 
  const [endDate, setEndDate] = useState(null); 
  const [userRole, setUserRole] = useState(localStorage.getItem("userRole") || null); 
  const [loadingRole, setLoadingRole] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false); 
  const [open, setOpen] = useState(false); 
  const [loadingApprovers, setLoadingApprovers] = useState(true);
  const [approvers, setApprovers] = useState([]);
  const [isUserApprover, setIsUserApprover] = useState(false);
  const [showPendingProjects, setShowPendingProjects] = useState(false);

  // const fetchApproverEmail = async () => {
  //   try {
  //     const response = await apiRequest.get("project/config/email");
  //     if (response.data.email) {
  //       setApproverEmail(response.data.email);
  //     }
  //   } catch (error) {
  //     console.error("Erro ao buscar o e-mail do Administrador do Hub:", error);
  //     displayErrorAlert("Erro ao buscar o e-mail do Administrador do Hub.");
  //   }
  // };

  // useEffect(() => {
  //   fetchApproverEmail();
  // }, []);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/user/role?email=${userData.email}`);
        if (response.data && response.data.role) {
          setUserRole(response.data.role);
          localStorage.setItem("userRole", response.data.role); 
        }
      } catch (error) {
        console.error("Erro ao buscar a role do usuário:", error);
        displayErrorAlert("Erro ao obter a role do usuário.");
      } finally {
        setLoadingRole(false);
      }
    };

    if (userData.email) {
      fetchUserRole();
    }
  }, [userData.email, displayErrorAlert]); 

  const handleApproverEmailSubmit = async () => {
    if (!approverEmail) {
      displayErrorAlert("Por favor, insira um e-mail válido.");
      return;
    }

    try {
      const response = await apiRequest.post("/configurations/update-approver", {
        key: "APROVER_EMAIL",
        value: approverEmail,
      });

      if (response.status === 200) {
        displaySuccessAlert("E-mail do Administrador do Hub atualizado com sucesso.");
        setIsApproverFieldVisible(false);
      }
    } catch (error) {
      console.error("Erro ao atualizar o e-mail do Administrador do Hub:", error);
      displayErrorAlert("Erro ao atualizar o e-mail do Administrador do Hub.");
    }
  };

  const [showProjectForm, setShowProjectForm] = useState(false);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [filter, setFilter] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [approvalMessage, setApprovalMessage] = useState("");

  const [formData, setFormData] = useState({
    numeroProjeto: "",
    contrato: "",
    nomeProjeto: "",
    descricao: "",
    data: null,
    areaResponsavel: "",
  });

  const [teamData, setTeamData] = useState([
    {
      id: 1,
      nome: "",
      empresa: "",
      papel: "",
      funcao: "",
      email: "",
      telefone: "",
      local: "",
      expiracao: null,
    },
  ]);

  const [rowId, setRowId] = useState(2);

  const handleShowProjectForm = () => {
    setShowProjectForm(false);
  };

  const handleHideProjectForm = () => {
    setShowProjectForm(false);
  };

  const fetchProjects = async (showSuccessMessage = true) => {
    setLoading(true);
    try {
        const response = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/list`);

        const validProjects = (response.data || []).map((project) => {
            let formattedDate = "N/A";

            if (project.created_at) {
                try {
                    // Converte a string 'DD/MM/YYYY HH:mm:ss' para um objeto Date
                    const parsedDate = parse(project.created_at, "dd/MM/yyyy HH:mm:ss", new Date());
                    formattedDate = format(parsedDate, "dd/MM/yyyy HH:mm:ss");
                } catch (error) {
                    console.error("Erro ao formatar data:", project.created_at, error);
                }
            }

            return {
                id: project.id,
                name: project.name || "N/A",
                status: project.status || "N/A",
                created_at: formattedDate,
            };
        }).sort((a, b) => (a.status === "Pendente" ? -1 : 1));

        setProjects(validProjects);

        if (showSuccessMessage) {
            displaySuccessAlert("Projetos carregados com sucesso.");
        }
    } catch (error) {
        console.error("Erro ao buscar projetos:", error);
        displayErrorAlert("Erro ao listar projetos.");
    } finally {
        setLoading(false);
    }
};

  const fetchProjectDetails = async (projectId) => {
    setLoading(true);
    try {
      // Obter o token de autenticação
      const tokenResponse = await getTokenAuthenticate();
      const token = tokenResponse.data.access_token;

      // Buscar os detalhes do projeto
      const hubId = process.env.REACT_APP_FF_HUB_ID;
      const url = `${process.env.REACT_APP_API_BASE_URL}project/v1/hubs/b.${hubId}/projects/${projectId}`;

      const response = await apiRequest.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Atualizar o projeto selecionado no estado
      setSelectedProject(response.data.data);
    } catch (error) {
      console.error("Erro ao buscar detalhes do projeto:", error);
      displayErrorAlert("Erro ao buscar detalhes do projeto.");
    } finally {
      setLoading(false);
    }
  };

const handleApprove = async (projectId) => {
    setLoadingAction(true);
    try {
        const response = await apiRequest.post(
            `${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/${projectId}/approve`, 
            {
                user_email: userData.email,  // Enviando usuário logado
            }
        );

        displaySuccessAlert(`Projeto ${projectId} aprovado com sucesso.`);
        fetchProjects();
    } catch (error) {
        console.error(`Erro ao aprovar projeto ${projectId}:`, error);

        // Captura a mensagem de erro do backend
        const errorMessage = error.response?.data?.error || "Erro desconhecido";

        // Verifica se o erro é do tipo "projeto já aprovado"
        if (error.response?.status === 409) {
            displayErrorAlert(errorMessage); // Mostra a mensagem específica do backend
        } else {
            displayErrorAlert("Verifique se o projeto está ativo ou arquivado.");
        }
    } finally {
        setLoadingAction(false);
    }
};


 const handleReject = async (projectId, motivo) => {
    setLoadingAction(true);
    try {
        await apiRequest.post(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/${projectId}/reject`, {
            motivo_rejeicao: motivo,
            user_email: userData.email,  //Enviando usuário logado
        });

        displaySuccessAlert(`Projeto ${projectId} reprovado com sucesso.`);
        fetchProjects();
    } catch (error) {
        console.error(`Erro ao rejeitar projeto ${projectId}:`, error);
        displayErrorAlert("Erro ao rejeitar o projeto.");
    } finally {
        setLoadingAction(false);
    }
};

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleCloseProjectDetails = () => {
    setSelectedProject(null); 
  };

  const filteredProjects = projects.filter((project) => {
    const matchesTextFilter =
      project.name.toLowerCase().includes(filter.toLowerCase()) ||
      project.status.toLowerCase().includes(filter.toLowerCase());

    // Converter a string `created_at` para um objeto `dayjs`
    const projectDate = dayjs(project.created_at, "DD/MM/YYYY HH:mm:ss");

    if (!projectDate.isValid()) {
      console.warn(`Data inválida detectada: ${project.created_at}`);
      return false; // Ignora projetos com datas inválidas
    }

    const start = startDate ? dayjs(startDate).startOf("day") : null;
    const end = endDate ? dayjs(endDate).endOf("day") : null;

    const matchesDateFilter =
      (!start || projectDate.isAfter(start) || projectDate.isSame(start, "day")) &&
      (!end || projectDate.isBefore(end) || projectDate.isSame(end, "day"));

    return matchesTextFilter && matchesDateFilter;
  });

 
  const handleAddRow = () => {
    const newRow = {
      id: rowId,
      nome: "",
      empresa: "",
      papel: "",
      funcao: "",
      email: "",
      telefone: "",
      local: "",
      expiracao: null,
    };
    setTeamData((prev) => [...prev, newRow]);
    setRowId(rowId + 1);
  };

  const handleRemoveRow = (id) => {
    setTeamData((prev) => prev.filter((row) => row.id !== id));
  };

  const processRowUpdate = (newRow) => {
    setTeamData((prev) =>
      prev.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  const handleDatePickerChange = (id, value) => {
    setTeamData((prev) =>
      prev.map((row) => (row.id === id ? { ...row, expiracao: value } : row))
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (value) => {
    setFormData({ ...formData, data: value });
  };

  const checkIfUserIsApprover = async () => {
    try {
      const response = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/approvers/list`);
      const approverEmails = response.data.map(a => a.email);
      return approverEmails.includes(userData?.email);
    } catch (error) {
      console.error("Erro ao verificar a lista de aprovadores:", error);
      return false;
    }
  };

  const columns = [
    { field: "name", headerName: "Nome", width: 250 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "created_at", headerName: "Criado em", width: 200 },
    {
      field: "actions",
      headerName: "Ações",
      width: 300,
      renderCell: (params) => {
        const isPending = params.row.status === "Pendente"; 

        return (
          <Grid container spacing={2}>
            {/* Botão DETALHES - Sempre habilitado */}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => navigate(`/bep/${params.row.id}`)}
              >
                Detalhes
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={() => handleApprove(params.row.id)}
                disabled={!isUserApprover || !isPending} 
              >
                Aceitar
              </Button>
            </Grid>

            {/* Botão RECUSAR - Reflete a permissão correta */}
            <Grid item>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => handleRejectClick(params.row.id)}
                disabled={!isUserApprover || !isPending} 
              >
                Recusar
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const projectDetailsColumns = [
    { field: "name", headerName: "Nome", width: 200 },
    { field: "number", headerName: "Número", width: 150 },
    { field: "platform", headerName: "Plataforma", width: 150 },
    { field: "members", headerName: "Membros", width: 150 },
    { field: "companies", headerName: "Empresas", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "template", headerName: "Template usado", width: 200 },
    { field: "start_date", headerName: "Data de início", width: 150 },
    { field: "end_date", headerName: "Data de término", width: 150 },
  ];

  useEffect(() => {
    const fetchApprovers = async () => {
      try {
        const response = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/approvers/list`);	
        const approverEmails = response.data.map(a => a.email);
        setApprovers(approverEmails);

        // Atualiza dinamicamente a permissão do usuário
        if (userData?.email) {
          setIsUserApprover(approverEmails.includes(userData.email));
        }
      } catch (error) {
        console.error("Erro ao buscar lista de aprovadores:", error);
        displayErrorAlert("Erro ao carregar lista de aprovadores.");
      } finally {
        setLoadingApprovers(false);
      }
    };

    fetchApprovers();
  }, [userData?.email]); // Sempre executa quando o email do usuário mudar

  const handleRejectClick = (projectId) => {
    setShowMotivoRejeicaoModal(projectId); // Define o ID do projeto para o modal de rejeição
  };

  return (
    <>
      <Backdrop 
        open={loadingAction} 
        style={{
          zIndex: 1301, 
          color: "#fff", 
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Overlay de carregamento enquanto busca a role */}
      {loadingRole && (
        <Backdrop
          open={true}
          style={{ zIndex: 1301, color: "#fff", display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Header />
      <main>
        <Card
          style={{
            padding: isSmallScreen ? "16px" : "24px",
            margin: isSmallScreen ? "16px" : "54px",
          }}
        >
          <FormSidebar />
          <Typography variant="h5" gutterBottom>
            Plano de Execução BIM - BEP
          </Typography>

          <Grid container spacing={2} alignItems="center" style={{ marginBottom: "24px" }}>
            {/* Botão Criar Projeto */}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowProjectForm(true)}
              >
                Criar Projeto
              </Button>
            </Grid>

            {/* Botão Buscar Projetos */}
           {["account_admin", "project_admin"].includes(userRole) && (
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate("/projects/search")}
              >
                Buscar Projetos
              </Button>
            </Grid>
          )}


            {userRole === "account_admin" && (
              <Grid item>
                <Button 
                  variant="outlined" 
                  color="secondary" 
                  onClick={() => {
                    setShowSearch(true); 
                    fetchProjects(true); 
                  }}
                >
                  Projetos Pendentes
                </Button>
              </Grid>
            )}

            {userRole === "account_admin" && (
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
                  Administrador do Hub
                </Button>
              </Grid>
            )}
          </Grid>


          {showSearch && (
            <>
             <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
              <Card 
                  style={{ 
                    padding: "16px", 
                    backgroundColor: theme.palette.mode === "dark" ? "#1e1e1e" : "#f5f5f5", 
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                    marginBottom: "24px"
                  }}
                >
                  <Typography variant="subtitle1" gutterBottom>
                    Filtros de Busca
                  </Typography>

                  <Grid container spacing={2} alignItems="center">
                    
                    {/* Campo de busca por nome ou status */}
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Buscar por Nome ou Status"
                        variant="outlined"
                        fullWidth
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    </Grid>

                    {/* Filtro por data inicial */}
                    <Grid item xs={12} sm={2}>
                      <DatePicker
                        label="Data Inicial"
                        value={startDate ? dayjs(startDate) : null}
                        onChange={(value) => setStartDate(value ? value.format("YYYY-MM-DD") : null)}
                        format="DD/MM/YYYY"
                        fullWidth
                      />
                    </Grid>

                    {/* Filtro por data final */}
                    <Grid item xs={12} sm={2}>
                      <DatePicker
                        label="Data Final"
                        value={endDate ? dayjs(endDate) : null}
                        onChange={(value) => setEndDate(value ? value.format("YYYY-MM-DD") : null)}
                        format="DD/MM/YYYY"
                        fullWidth
                      />
                    </Grid>

                    {/* Botão para limpar filtros */}
                    <Grid item xs={12} sm={2}>
                      <Button 
                        variant="contained" 
                        color="secondary" 
                        fullWidth
                        onClick={() => {
                          setStartDate(null);
                          setEndDate(null);
                          setFilter("");
                        }}
                      >
                        Limpar
                      </Button>
                    </Grid>

                  </Grid>
                </Card>

            </LocalizationProvider>

            {/* Exibir aviso se o usuário NÃO for aprovador */}
            {!isUserApprover && (
              <Alert severity="warning" style={{ marginBottom: "16px", textAlign: "center" }}>
                Apenas Administradores do Hub podem aceitar ou recusar este projeto.
              </Alert>
            )}

              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  columns={columns}
                  rows={filteredProjects}
                  loading={loading}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                />
              </div>
            </>
          )}

          {approvalMessage && (
            <Typography variant="h6" color="primary" align="center" gutterBottom>
              {approvalMessage}
            </Typography>
          )}

          {/* Alerta de Sucesso */}
          <Snackbar
            open={showSuccessAlert}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">{successMessage}</Alert>
          </Snackbar>

          {/* Alerta de Erro */}
          <Snackbar
            open={showErrorAlert}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>

          {/* Detalhes do projeto selecionado */}
          {selectedProject && (
            <div style={{ marginTop: "24px" }}>
              <IconButton onClick={handleCloseProjectDetails} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Detalhes do Projeto</Typography>
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  title="Detalhes do Projeto"
                  columns={projectDetailsColumns}
                  rows={[
                    {
                      id: selectedProject.id,
                      name: selectedProject.attributes.name || "N/A",
                      number: selectedProject.attributes.number || "N/A",
                      platform: selectedProject.attributes.platform || "N/A",
                      members: selectedProject.attributes.members || 0,
                      companies: selectedProject.attributes.companies || 0,
                      status: selectedProject.attributes.status || "N/A",
                      template: selectedProject.attributes.template || "N/A",
                      start_date: selectedProject.attributes.start_date
                        ? format(new Date(selectedProject.attributes.start_date), "dd/MM/yyyy")
                        : "N/A",
                      end_date: selectedProject.attributes.end_date
                        ? format(new Date(selectedProject.attributes.end_date), "dd/MM/yyyy")
                        : "N/A",
                      created_at: selectedProject.attributes.created_at
                        ? format(new Date(selectedProject.attributes.created_at), "dd/MM/yyyy HH:mm")
                        : "N/A",
                    },
                  ]}
                  loading={loading}
                  pageSize={1}
                  rowsPerPageOptions={[1]}
                />
              </div>
            </div>
          )}

          {/* Formulário de criação de projeto */}
          {showProjectForm && (
            <FormBep
              onSave={() => {
                setShowProjectForm(false); // Fecha o formulário ao salvar
                fetchProjects(false); // Atualiza os projetos sem exibir mensagem de sucesso
              }}
              onCancel={() => setShowProjectForm(false)} // Fecha o formulário ao cancelar
              userEmail={userData.email} // Envia o e-mail do usuário logado
            />
          )}
        </Card>
        <ApproverManagement open={open} setOpen={setOpen} />
      </main>
      <Footer />
      <JustificativaModal 
        open={showJustificativaModal}
        onClose={() => setShowJustificativaModal(false)}
        onSave={(text) => setJustificativa(text)}
      />
      <MotivoRejeicaoModal 
        open={!!showMotivoRejeicaoModal} // Verifica se tem um ID válido
        onClose={() => setShowMotivoRejeicaoModal(false)} 
        projectId={showMotivoRejeicaoModal} //Agora passa corretamente o ID para o modal
        onRejectSuccess={(motivo) => {
          fetchProjects(); // Atualiza lista ao recusar
          setShowMotivoRejeicaoModal(false);
        }} 
      />
    </>
  );
};

export default Bep;

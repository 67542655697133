import axios from 'axios';

// Carrega a URL correta do .env
const API_URL = process.env.REACT_APP_FLASK_BACKEND_BEP;

axios.defaults.baseURL = API_URL;


export const createProject = async (projectData) => {
    try {
        console.log('Enviando requisição para:', `${API_URL}/project/create`);
        const response = await axios.post('/project/create', projectData);
        return response;
    } catch (error) {
        console.error('Erro na criação do projeto:', error.response || error);
        throw error;
    }
};


import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  IconButton,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { AlertContext } from "../../Provider/AlertProvider";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import apiRequest from "../../service/apiRequest";

const ApproverManagement = ({ open, setOpen }) => {
  const { displaySuccessAlert, displayErrorAlert } = useContext(AlertContext);
  const { userData } = useContext(AecFolderContext);
  const [approvers, setApprovers] = useState([]);
  const [adminEmails, setAdminEmails] = useState([]);
  const [selectedApprover, setSelectedApprover] = useState(null);
  const [loadingAdmins, setLoadingAdmins] = useState(false);
  const [loadingApprovers, setLoadingApprovers] = useState(true);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [isUserApprover, setIsUserApprover] = useState(false);

  // Função para buscar lista de aprovadores do backend
  const fetchApprovers = async () => {
    setLoadingApprovers(true);
    try {
      const response = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/approvers/list`);
      setApprovers(response.data || []);
    } catch (error) {
      displayErrorAlert("Erro ao carregar lista de aprovadores.");
    } finally {
      setLoadingApprovers(false);
    }
  };

  useEffect(() => {
    if (open) fetchApprovers();
  }, [open]);

  // Buscar lista de administradores account_admin
  const fetchAdminEmails = async () => {
    setLoadingAdmins(true);
    try {
      const response = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/list-account_admin`
      );
      setAdminEmails(response.data?.admin_emails.sort() || []);
    } catch (error) {
      displayErrorAlert("Erro ao carregar lista de administradores.");
    } finally {
      setLoadingAdmins(false);
    }
  };

  const handleAddApprover = async () => {
    if (!selectedApprover) {
      displayErrorAlert("Selecione um e-mail válido.");
      return;
    }

    try {
      await apiRequest.post(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/approvers/add`, {
        email: selectedApprover,
      });

      setSelectedApprover(null);
      displaySuccessAlert("Administrador do Hub adicionado!");

      //Atualizar lista de aprovadores diretamente da API para garantir IDs corretos
      fetchApprovers();
    } catch (error) {
      displayErrorAlert("Erro ao adicionar aprovador.");
    }
  };

 
  const handleDeleteApprover = async (id) => {
    try {
      await apiRequest.delete(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/approvers/delete/${id}`);

      displaySuccessAlert("Administrador do Hub removido.");

      //Atualizar lista de aprovadores diretamente da API para evitar problemas de ID
      fetchApprovers();
    } catch (error) {
      displayErrorAlert("Erro ao remover aprovador.");
    }
  };

  const columns = [
    { field: "email", headerName: "E-mail", width: 250 },
    {
      field: "actions",
      headerName: "Ações",
      width: 100,
      renderCell: (params) => (
        <IconButton color="error" onClick={() => handleDeleteApprover(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5">Gerenciar Administradores do Hub</Typography>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} alignItems="center" style={{ marginTop: "16px" }}>
          <Grid item xs={9}>
            <Autocomplete
              open={autoCompleteOpen}
              onOpen={() => {
                if (adminEmails.length === 0) fetchAdminEmails();
                setAutoCompleteOpen(true);
              }}
              onClose={() => setAutoCompleteOpen(false)}
              options={adminEmails.length > 0 ? adminEmails : ["Carregando..."]}
              getOptionLabel={(option) => option}
              value={selectedApprover}
              onChange={(event, newValue) => setSelectedApprover(newValue)}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Administrador do Hub"
                  variant="outlined"
                  fullWidth
                  disabled={loadingAdmins}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: loadingAdmins && (
                      <CircularProgress color="inherit" size={20} style={{ marginRight: 10 }} />
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={3}>
            <IconButton color="primary" onClick={handleAddApprover}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>

        {loadingApprovers ? (
          <Box display="flex" justifyContent="center" alignItems="center" height={100}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box mt={2}>
            <DataGrid rows={approvers} columns={columns} pageSize={5} autoHeight />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ApproverManagement;
import React from 'react';
import styles from './index.module.css';

import Title from '../../atoms/Title';
import Paragraph from '../../atoms/Paragraph';

/**
 * Card component displaying a title and a paragraph.
 * Tells the user the purpose of the system and briefly describe how to use it.
 */
const Card = () => {
  return (
    <div className={styles.card}>
      <Title content="Ferramenta de Carga em Lote - SAT" id="formTitle"/>
      <Paragraph content="Para utilizar a ferramenta de carga de dados em lote, é necessário primeiro realizar o carregamento dos arquivos no ACC e, em seguida, proceder com a carga de dados. Para isso, basta clicar no botão abaixo, selecionar o Hub e o Projeto de interesse e escolher o arquivo de dados no formato Excel (.xlsx). No entanto, é importante garantir que todos os arquivos carregados no ACC que contenham metadados estejam listados no arquivo Excel e que os nomes dos arquivos incluam a extensão (por exemplo, 'documento.pdf'). Além disso, cada guia do arquivo Excel deve ter o mesmo nome da pasta correspondente no ACC." id="formDescription"/>
    </div>
  )
}

export default Card;
import React, { useState, useContext, useEffect } from "react";
import { Dialog, Button, Typography, Grid } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ColorModeContext } from "../../Provider/ColorModeProvider";
import { AlertContext } from "../../Provider/AlertProvider";
import { AecFolderContext } from "../../Provider/AecFolderProvider"; 
import apiRequest from "../../service/apiRequest"; 

const MAX_CHARACTERS = 2000;

const MotivoRejeicaoModal = ({ open, onClose, projectId, onRejectSuccess }) => {
  const [motivo, setMotivo] = useState("");
  const { getDesignTokens } = useContext(ColorModeContext);
  const { displayErrorAlert, displaySuccessAlert } = useContext(AlertContext);
  const { userData } = useContext(AecFolderContext); 
  const theme = getDesignTokens(localStorage.getItem("theme") ?? "dark");

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .ql-editor::before {
        color: ${theme.palette.mode === "dark" ? "#ffffff" : "#6b6b6b"} !important;
      }
    `;
    document.head.appendChild(style);
  }, [theme]);

  const handleChange = (content) => {
    setMotivo(content.replace(/\n/g, "<br/>"));
  };

  const handleConfirmReject = async () => {
    if (!projectId) {
      displayErrorAlert("Erro: ID do projeto não encontrado.");
      return;
    }

    const plainText = motivo.replace(/<[^>]+>/g, "").trim();
    if (plainText.length === 0) {
      displayErrorAlert("O motivo da rejeição não pode estar vazio.");
      return;
    }

    if (!userData?.email) {
      displayErrorAlert("Erro: Usuário não autenticado.");
      return;
    }

    try {
      const response = await apiRequest.post(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/${projectId}/reject`, {
        motivo_rejeicao: motivo,
        user_email: userData.email,  // ✅ Corrigido
      });

      if (response.status === 200) {
        displaySuccessAlert("Projeto reprovado com sucesso!");
        onRejectSuccess(motivo);
        onClose();
      }
    } catch (error) {
      console.error("Erro ao recusar o projeto:", error);

      // Melhorando a exibição de erro para depuração
      if (error.response) {
        displayErrorAlert(`Erro ao recusar o projeto: ${error.response.data.error || "Erro desconhecido"}`);
      } else {
        displayErrorAlert("Erro ao recusar o projeto. Verifique sua conexão.");
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Grid container padding={3} spacing={2} justifyContent="center">
        <Grid item xs={12} textAlign="center">
          <Typography variant="h6" fontWeight="bold">
            Justificativa da Recusa
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              borderRadius: "5px",
              backgroundColor: theme.palette.mode === "dark" ? "#1e1e1e" : "#ffffff",
              border: "1px solid #ccc",
              padding: "8px",
            }}
          >
            <ReactQuill
              theme="snow"
              value={motivo}
              onChange={handleChange}
              placeholder="Explique o motivo da recusa (máx. 2000 caracteres)"
              style={{
                color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                backgroundColor: theme.palette.mode === "dark" ? "#1e1e1e" : "#ffffff",
                height: "350px",
                overflowY: "auto",
                borderRadius: "5px",
              }}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
            />
          </div>
          <Typography variant="caption" display="block" align="right" style={{ marginTop: "5px" }}>
            {motivo.replace(/<[^>]+>/g, "").trim().length}/{MAX_CHARACTERS} caracteres
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2} style={{ marginTop: "16px" }}>
            <Grid item>
              <Button variant="outlined" color="secondary" onClick={onClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleConfirmReject}>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default MotivoRejeicaoModal;

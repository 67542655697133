import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import apiRequest from "../../service/apiRequest";
import Header from "../../components/Header";
import AddProjectMembers from "./AddProjectMembers";
import UserManagementModal from "./UserManagementModal"; // Import the new modal component

const ProjectSearchAndDetails = () => {
  const [projects, setProjects] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [projectDetails, setProjectDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddMembers, setOpenAddMembers] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const [openUserManagement, setOpenUserManagement] = useState(false);
  const [projectUsers, setProjectUsers] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_FLASK_BACKEND_BEP;

  const theme = useTheme();
  const navigate = useNavigate();

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const fetchProjects = async () => {
    setLoadingProjects(true);
    try {
      const response = await apiRequest.get(`${API_BASE_URL}/list/projects`);
      setProjects(response.data.projects || []);
    } catch (error) {
      console.error("Erro ao buscar projetos:", error);
    } finally {
      setLoadingProjects(false);
    }
  };

  const fetchProjectDetails = async (projectId) => {
    setLoadingDetails(true);
    try {
      const response = await apiRequest.get(`${API_BASE_URL}/list/project/details/${projectId}`);

      setProjectDetails(response.data.project || {});
    } catch (error) {
      console.error("Erro ao buscar detalhes do projeto:", error);
    } finally {
      setLoadingDetails(false);
    }
  };

  const fetchProjectUsers = async (projectId) => {
    try {
      const response = await apiRequest.get(`${API_BASE_URL}/list/project/users/${projectId}`);

      setProjectUsers(response.data.users || []);
    } catch (error) {
      console.error("Erro ao buscar usuários do projeto:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    fetchProjectDetails(project.id);
  };

  const handleCloseDetails = () => {
    setSelectedProject(null);
    setProjectDetails(null);
  };

 const handleOpenAddMembers = (projectId, projectName) => {
  setSelectedProjectId(projectId);
  setSelectedProjectName(projectName);
  setOpenAddMembers(true);
};

  const handleOpenUserManagement = (projectId) => {
    fetchProjectUsers(projectId);
    setSelectedProjectId(projectId); // Ensure projectId is set
    setOpenUserManagement(true);
  };

  const handleCloseUserManagement = () => {
    setOpenUserManagement(false);
    setProjectUsers([]);
  };

  const filteredProjects = searchTerm
    ? projects.filter((project) =>
        project.name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : projects;

    const parseISODate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return new Date(Date.UTC(year, month - 1, day));
  };


  return (
    <>
      <Header />
      <Card style={{ padding: "20px", margin: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Buscar Projetos
        </Typography>

        <TextField
          label="Buscar por Nome"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {loadingProjects ? (
          <CircularProgress />
        ) : (
          <div style={{ height: 400, width: "100%", overflowX: "auto" }}>
            <DataGrid
              columns={[
                { field: "name", headerName: "Nome do Projeto", width: 300 },
                {
                  field: "actions",
                  headerName: "Ações",
                  width: 450, // Increased to accommodate three buttons
                  renderCell: (params) => (
                    <div style={{ display: "flex", gap: "10px", flexGrow: 1 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleProjectClick(params.row)}
                      >
                        Ver Detalhes
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => handleOpenAddMembers(params.row.id, params.row.name)}
                      >
                        Adicionar Membros
                      </Button>
                     <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#bdbdbd",
                        color: "#000",
                        "&:hover": { backgroundColor: "#9e9e9e" },
                      }}
                      size="small"
                      onClick={() => handleOpenUserManagement(params.row.id, params.row.name)}
                    >
                      Gerenciar Usuários
                    </Button>


                    </div>
                  ),
                },
              ]}
              rows={filteredProjects}
              getRowId={(row) => row.id}
              pageSize={5}
            />
          </div>
        )}

        <Dialog open={Boolean(selectedProject)} onClose={handleCloseDetails} fullWidth maxWidth="md">
          <DialogTitle>
            Detalhes do Projeto
            <IconButton onClick={handleCloseDetails} style={{ position: "absolute", right: 10, top: 10 }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {loadingDetails ? (
              <CircularProgress />
            ) : projectDetails ? (
              <Grid container spacing={1} justifyContent="center">
                {projectDetails.imageUrl && (
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <img
                      src={projectDetails.imageUrl}
                      alt="Projeto"
                      style={{ width: "80%", maxWidth: "300px", borderRadius: "8px" }}
                    />
                  </Grid>
                )}
                {Object.entries({
                  "Nome": projectDetails.name,
                  "Status": projectDetails.status,
                  "Plataforma": projectDetails.platform,
                  "Membros": projectDetails.memberCount,
                  "Empresas": projectDetails.companyCount,
                  "Valor do Projeto": projectDetails.projectValue?.value ? formatCurrency(projectDetails.projectValue.value) : null,
                  "Início": projectDetails.startDate
                    ? format(new Date(projectDetails.startDate + "T12:00:00"), "dd/MM/yyyy", { locale: ptBR })
                    : null,
                  "Término": projectDetails.endDate
                    ? format(new Date(projectDetails.endDate + "T12:00:00"), "dd/MM/yyyy", { locale: ptBR })
                    : null,


                }).map(([key, value]) =>
                  value ? (
                    <Grid item xs={12} key={key} style={{ marginBottom: "4px" }}>
                      <Typography><strong>{key}:</strong> {value}</Typography>
                    </Grid>
                  ) : null
                )}
                {/* {projectDetails.products?.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h6">Produtos Ativos</Typography>
                    <Grid container spacing={1} justifyContent="center">
                      {projectDetails.products.map((product) => (
                        <Grid item key={product.key} style={{ textAlign: "center" }}>
                          <img src={product.icon} alt={product.name} style={{ width: 40, height: 40 }} />
                          <Typography variant="caption">{product.name}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )} */}
              </Grid>
            ) : (
              <Typography>Não foi possível carregar os detalhes do projeto.</Typography>
            )}
          </DialogContent>
        </Dialog>
        <AddProjectMembers open={openAddMembers} onClose={() => setOpenAddMembers(false)} projectId={selectedProjectId} projectName={selectedProjectName} />
        <UserManagementModal
          open={openUserManagement}
          onClose={handleCloseUserManagement}
          users={projectUsers}
          projectId={selectedProjectId}
        />
      </Card>
    </>
  );
};

export default ProjectSearchAndDetails;

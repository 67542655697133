import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  TextField,
  Chip,
  Grid,
  MenuItem,
  CircularProgress,
  Typography,
  Autocomplete,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete"; 
import apiRequest from "../../service/apiRequest";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { AlertContext } from "../../Provider/AlertProvider";

const AddProjectMembers = ({ open, onClose, projectId, projectName  }) => {
  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [companies, setCompanies] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationLimit, setPaginationLimit] = useState(100); // Limita os itens exibidos na lista de funções
  const [searchTerm, setSearchTerm] = useState(""); // Termo de busca para funções
  const [companySearchTerm, setCompanySearchTerm] = useState(""); // Termo de busca para empresas
  const [hubUsers, setHubUsers] = useState([]); // Define hubUsers state
  const filteredRoles = roles.filter((role) => role.name.toLowerCase().includes(searchTerm.toLowerCase()));
  const [roleSearchTerms, setRoleSearchTerms] = useState([]);

 
  const API_BASE_URL = process.env.REACT_APP_FLASK_BACKEND_BEP;
  const { displaySuccessAlert, displayErrorAlert } = useContext(AlertContext);

useEffect(() => {
  if (open) {
    fetchCompanies();
    fetchRoles(); // 🚀 Agora não precisamos de offset
    fetchHubUsers();
    setEmailInput(""); 
    resetForm();
  }
}, [open]);




const fetchCompanies = async () => {
  if (companies.length > 0) return; // Evita buscar novamente se já carregado

  try {
    const response = await apiRequest.get(`${API_BASE_URL}/list/companies`);
    setCompanies(response.data.companies || []);
  } catch (error) {
    console.error("Erro ao buscar empresas:", error);
    setCompanies([]);
  }
};


const fetchRoles = async () => {
  setLoading(true);
  try {
    const response = await apiRequest.get(`${API_BASE_URL}/list/project/users/roles`);
    
    console.log("📌 Dados recebidos da API:", response.data); // Log para verificar se os dados estão vindo corretamente
    
    setRoles(response.data || []); // 🔥 Garante que os roles sejam armazenados corretamente
  } catch (error) {
    console.error("❌ Erro ao buscar funções:", error);
    setRoles([]); // 🔥 Evita crash caso a API falhe
  } finally {
    setLoading(false);
  }
};



const uniqueRoles = roles.filter((role, index, self) =>
  index === self.findIndex((r) => r.id === role.id)
);

const sortedRoles = [...uniqueRoles].sort((a, b) => {
  const isSelectedA = users.some((user) => user.roleIds.includes(a.id));
  const isSelectedB = users.some((user) => user.roleIds.includes(b.id));

  if (isSelectedA === isSelectedB) {
    return a.name.localeCompare(b.name); // Ordena alfabeticamente se nenhum estiver selecionado
  }

  return isSelectedB - isSelectedA; // Mantém os selecionados no topo
});



const fetchHubUsers = async () => {
  try {
    const response = await apiRequest.get(`${API_BASE_URL}/list/hub_users`);
    console.log("📌 Dados retornados de /list/hub_users:", response.data);

    if (response.data && Array.isArray(response.data)) {
      setHubUsers(response.data.map((email) => ({ email }))); // Converte string[] para objeto[]
    } else {
      console.warn("⚠️ Formato inesperado da resposta da API:", response.data);
      setHubUsers([]);
    }
  } catch (error) {
    console.error("❌ Erro ao buscar usuários do Hub:", error);
    setHubUsers([]);
  }
};



const handleAddEmail = (event, newValue) => {
  if (newValue) {
    const emailList = newValue
      .split(/[,\s]+/) // Aceita separação por vírgula ou espaço
      .map(email => email.trim())
      .filter(email => email && !emails.includes(email)); // Remove duplicatas e espaços extras

    setEmails(prevEmails => [...prevEmails, ...emailList]); // Adiciona os novos e-mails na lista
    setTimeout(() => setEmailInput(""), 100); // Limpa o campo de entrada
  }
};

// 🔹 Detecta Enter ou Vírgula no input e converte em chip
const handleKeyDown = (event) => {
  if (event.key === "Enter" || event.key === ",") {
    event.preventDefault(); // Evita que o enter crie uma nova linha ou envie um formulário
    if (emailInput.trim() !== "") {
      handleAddEmail(null, emailInput); // Adiciona o email atual ao pressionar Enter ou vírgula
    }
  }
};



  const resetForm = () => {
    setEmails([]);
    setUsers([]);
    setEmailInput("");
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };



  const handleRemoveEmail = (email) => {
    setEmails(emails.filter((e) => e !== email));
  };

const handleInsertUsers = () => {
  const newUsers = emails.map((email) => ({
    email,
    companyId: "",
    roleIds: [],
    accessLevel: "member",
    expirationTimestamp: "",
  }));

  setUsers((prevUsers) => [...prevUsers, ...newUsers]);
  setRoleSearchTerms((prev) => [...prev, ...newUsers.map(() => "")]); // <- Aqui
  setEmails([]);
  setEmailInput("");
};



const handleCompanyChange = (index, newValue) => {
  setUsers((prevUsers) =>
    prevUsers.map((user, i) =>
      i === index
        ? { ...user, companyId: newValue?.id || "", companyName: newValue?.name || "" }
        : user
    )
  );
};

const handleRoleChange = (index, roleId) => {
  setUsers((prevUsers) =>
    prevUsers.map((user, i) => {
      if (i === index) {
        const isSelected = user.roleIds.includes(roleId);
        const updatedRoles = isSelected
          ? user.roleIds.filter((r) => r !== roleId) // Remove se já existir
          : [...new Set([...user.roleIds, roleId])]; // Adiciona se não existir e evita duplicação

        return { ...user, roleIds: updatedRoles };
      }
      return user;
    })
  );
};




  const handleAccessLevelChange = (index, accessLevel) => {
    setUsers((prevUsers) =>
      prevUsers.map((user, i) =>
        i === index ? { ...user, accessLevel } : user
      )
    );
  };

  const handleRemoveUser = (index) => {
    setUsers((prevUsers) => prevUsers.filter((_, i) => i !== index));
  };

const handleSubmit = async () => {
  setLoading(true);

  try {
    // Converte a data do formato DD/MM/YYYY para YYYY-MM-DDTHH:MM:SS antes de enviar
    const formattedUsers = users.map((user) => {
      let formattedExpiration = "";
      if (user.expirationTimestamp) {
        const dateParts = user.expirationTimestamp.split("/");
        if (dateParts.length === 3) {
          formattedExpiration = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T00:00:00`;
        }
      }

      return {
        ...user,
        expirationTimestamp: formattedExpiration || null, // Garante que não envie string vazia
      };
    });

    console.log("📌 Dados enviados para o backend:", formattedUsers);

    const response = await apiRequest.post(`${API_BASE_URL}/users/add_users`, {
      project_id: projectId,
      users: formattedUsers,
    });

    let responseData = response.data;

    console.log("📌 Resposta da API:", responseData);

    if (!responseData || !responseData.results) {
      console.warn("⚠️ API retornou uma resposta inválida!");
      displayErrorAlert("Erro inesperado ao processar os convites.", { autoHideDuration: 5000 });
      return;
    }

    const results = responseData.results;

    // Filtra os usuários que já existem no projeto
    const failedUsers = users.filter(
      (user) => !results.some((res) => res.email === user.email)
    );

    if (failedUsers.length > 0) {
      const errorMessages = failedUsers.map((user) => `✱${user.email}`).join("\n");

      displayErrorAlert(`Alguns usuários já fazem parte do projeto:\n\n${errorMessages}`, {
        autoHideDuration: 6000,
      });
    }

    if (results.length > 0) {
      displaySuccessAlert("Convites enviados com sucesso!", { autoHideDuration: 5000 });

      // Aguardar um pouco antes de fechar o modal para o usuário visualizar a mensagem
      setTimeout(() => {
        onClose();
      }, 5000);
    }
  } catch (error) {
    console.error("❌ Erro ao enviar convites:", error);

    const errorMessage = error.response?.data?.error || "Erro ao enviar convites!";
    displayErrorAlert(errorMessage, { autoHideDuration: 5000 });
  } finally {
    setLoading(false);
  }
};
const convertToDatetimeLocal = (inputDate) => {
  // Expressão regular para identificar datas no formato DD/MM/YYYY
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  const match = inputDate.match(dateRegex);

  if (!match) return null; // Se não corresponder ao formato esperado, retorna null

  const [, day, month, year] = match;
  return `${year}-${month}-${day}T00:00`; // Retorna no formato esperado pelo datetime-local
};


  return (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <IconButton onClick={onClose} style={{ position: "absolute", right: 10, top: 10 }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography variant="h6" style={{ marginBottom: 16, fontWeight: "bold" }}>
          Adicionar Membros ao Projeto: {projectName}
        </Typography>


        
         <Autocomplete freeSolo
          multiple
          options={hubUsers} // Exibe todos os usuários disponíveis
          getOptionLabel={(option) => option.email || ""} // Usa a chave "email"
          value={emails.map((email) => ({ email }))} // Converte emails para objetos [{ email }]
          onChange={(event, newValue) => setEmails(newValue.map((user) => user.email))}
          inputValue={emailInput}
          onInputChange={(event, newInputValue) => setEmailInput(newInputValue)}
          filterOptions={(options, state) => {
            // Se não há input, mostra todos os usuários disponíveis
            if (!state.inputValue) return options;
            
            // Filtra usuários pelo email
            return options.filter((user) =>
              user.email.toLowerCase().includes(state.inputValue.toLowerCase())
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Adicionar e-mails"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 16 }}
              InputLabelProps={{ shrink: true }}
              onKeyDown={handleKeyDown} // Captura enter e vírgula
              onPaste={(event) => {
                event.preventDefault();
                const pastedText = event.clipboardData.getData("text");
                handleAddEmail(event, pastedText);
              }}
            />
          )}
        />

        <Button onClick={handleInsertUsers} variant="contained" style={{ marginTop: 16 }}>
          Inserir
        </Button>
        {users.length > 0 && (
          <>
            <Typography variant="h6" style={{ marginTop: 16 }}>
              Configurar Usuários
            </Typography>
            <Grid container spacing={2}>
              {users.map((user, index) => (
                <Grid container item spacing={2} key={index} alignItems="center" wrap="nowrap">
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={user.email}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} md={2} lg={2}>
                    <Autocomplete
                      options={companies}
                      getOptionLabel={(option) => option.name}
                      value={companies.find((comp) => comp.id === user.companyId) || null}
                      onChange={(event, newValue) => handleCompanyChange(index, newValue)}
                      renderInput={(params) => <TextField {...params} label="Empresa" variant="outlined" fullWidth />}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Autocomplete
                    multiple
                    freeSolo
                    options={sortedRoles}
                    getOptionLabel={(option) => option.name || ""}
                    value={roles.filter((role) => users[index]?.roleIds.includes(role.id))}
                    inputValue={roleSearchTerms[index] || ""}
                    onInputChange={(event, newValue) => {
                      setRoleSearchTerms((prev) =>
                        prev.map((term, i) => (i === index ? newValue : term))
                      );
                    }}
                    onChange={(event, newValue) => {
                      const newRoleIds = newValue.map((role) => role.id);
                      setUsers((prevUsers) =>
                        prevUsers.map((u, i) => (i === index ? { ...u, roleIds: newRoleIds } : u))
                      );
                    }}
                    filterOptions={(options) =>
                      options.filter((role) =>
                        role.name.toLowerCase().includes((roleSearchTerms[index] || "").toLowerCase())
                      )
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderTags={(value) =>
                      value.length > 1
                        ? [<Chip key="multi" label={`${value[0].name} +${value.length - 1}`} />]
                        : value.map((option) => <Chip key={option.id} label={option.name} />)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Função"
                        variant="outlined"
                        fullWidth
                        placeholder="Digite para buscar..."
                      />
                    )}
                  />



                  </Grid>

                  <Grid item xs={12} sm={2} md={2} lg={2}>
                    <TextField
                      select
                      fullWidth
                      label="Acesso"
                      value={user.accessLevel}
                      onChange={(event) => handleAccessLevelChange(index, event.target.value)}
                    >
                      <MenuItem value="member">Membro</MenuItem>
                      <MenuItem value="admin">Administrador</MenuItem>
                    </TextField>
                  </Grid>

                 <Grid item xs={12} sm={3} md={3} lg={3} display="flex" alignItems="center" justifyContent="space-between">
                <TextField
                  label="Expiração"
                  type="text"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={user.expirationTimestamp || ""}
                  placeholder="DD/MM/AAAA"
                  error={!!user.expirationError}
                  helperText={user.expirationError} // Removemos o exemplo fixo
                  inputProps={{ maxLength: 10 }}
                  onChange={(event) => {
                    let value = event.target.value.replace(/\D/g, ""); // Remove tudo que não for número
                    let cursorPosition = event.target.selectionStart; // Captura a posição do cursor
                    let previousLength = user.expirationTimestamp?.length || 0; // Tamanho anterior

                    if (value.length > 8) {
                      value = value.substring(0, 8); // Garante que não passe de 8 dígitos
                    }

                    let formattedDate = "";
                    if (value.length > 0) formattedDate = value.substring(0, 2);
                    if (value.length > 2) formattedDate += "/" + value.substring(2, 4);
                    if (value.length > 4) formattedDate += "/" + value.substring(4, 8);

                    setUsers((prevUsers) =>
                      prevUsers.map((u, i) =>
                        i === index ? { ...u, expirationTimestamp: formattedDate, expirationError: "" } : u
                      )
                    );

                    setTimeout(() => {
                      let newCursorPosition = cursorPosition + (formattedDate.length - previousLength);
                      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
                    }, 0);
                  }}
                  onBlur={() => {
                    const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
                    const match = user.expirationTimestamp.match(dateRegex);

                    if (!match) {
                      setUsers((prevUsers) =>
                        prevUsers.map((u, i) =>
                          i === index ? { ...u, expirationError: "Formato inválido! Use DD/MM/AAAA." } : u
                        )
                      );
                      return;
                    }

                    const day = parseInt(match[1], 10);
                    const month = parseInt(match[2], 10);
                    const year = parseInt(match[3], 10);

                    if (month < 1 || month > 12) {
                      setUsers((prevUsers) =>
                        prevUsers.map((u, i) =>
                          i === index ? { ...u, expirationError: "Mês inválido! Use um valor entre 01 e 12." } : u
                        )
                      );
                      return;
                    }

                    const daysInMonth = new Date(year, month, 0).getDate();
                    if (day < 1 || day > daysInMonth) {
                      setUsers((prevUsers) =>
                        prevUsers.map((u, i) =>
                          i === index ? { ...u, expirationError: `Dia inválido! O mês ${month} tem no máximo ${daysInMonth} dias.` } : u
                        )
                      );
                      return;
                    }

                    setUsers((prevUsers) =>
                      prevUsers.map((u, i) =>
                        i === index ? { ...u, expirationError: "" } : u
                      )
                    );
                  }}
                  onPaste={(event) => {
                    event.preventDefault();
                    let pastedText = event.clipboardData.getData("text").trim();
                    let formattedDate = pastedText.replace(/\D/g, "");

                    if (formattedDate.length === 8) {
                      formattedDate = `${formattedDate.substring(0, 2)}/${formattedDate.substring(2, 4)}/${formattedDate.substring(4, 8)}`;
                      setUsers((prevUsers) =>
                        prevUsers.map((u, i) =>
                          i === index ? { ...u, expirationTimestamp: formattedDate, expirationError: "" } : u
                        )
                      );
                    } else {
                      setUsers((prevUsers) =>
                        prevUsers.map((u, i) =>
                          i === index ? { ...u, expirationError: "Formato inválido! Use DD/MM/AAAA." } : u
                        )
                      );
                    }
                  }}
                />




                <IconButton onClick={() => handleRemoveUser(index)} sx={{ color: "red", marginLeft: 1 }}>
                  <DeleteIcon />
                </IconButton>
              </Grid>

                </Grid>
              ))}
            </Grid>

            <Button onClick={handleSubmit} variant="contained" color="primary" style={{ marginTop: 16 }}>
              Enviar Convites
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddProjectMembers;
import React, { useState, useContext, useEffect } from "react";
import { Dialog, Button, Typography, Grid } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ColorModeContext } from "../../Provider/ColorModeProvider";

const MAX_CHARACTERS = 5000;

const JustificativaModal = ({ open, onClose, onSave }) => {
  const [justificativa, setJustificativa] = useState("");
  const { getDesignTokens } = useContext(ColorModeContext);
  const theme = getDesignTokens(localStorage.getItem("theme") ?? "dark");

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .ql-editor::before {
        color: ${theme.palette.mode === "dark" ? "#ffffff" : "#6b6b6b"} !important;
      }
    `;
    document.head.appendChild(style);
  }, [theme]);

  const handleChange = (content, delta, source, editor) => {
    if (editor.getLength() > MAX_CHARACTERS + 1) {
      alert("O limite de 5000 caracteres foi atingido.");
      return;
    }
    setJustificativa(content);
  };

  const handleSave = () => {
    if (justificativa.trim().length === 0) {
      alert("A justificativa não pode estar vazia.");
      return;
    }
    onSave(justificativa);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Grid container padding={3} spacing={2} justifyContent="center">
        {/* Título Centralizado */}
        <Grid item xs={12} textAlign="center">
          <Typography variant="h6" fontWeight="bold">
            Justificativa
          </Typography>
        </Grid>

       
        <Grid item xs={12}>
          <div
            style={{
              borderRadius: "5px",
              backgroundColor: theme.palette.mode === "dark" ? "#1e1e1e" : "#ffffff",
              border: "1px solid #ccc", 
              padding: "8px",
            }}
          >
            <ReactQuill
              theme="snow"
              value={justificativa}
              onChange={handleChange}
              placeholder="Descreva o motivo da criação do projeto (máx. 5000 caracteres)"
              style={{
                color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                backgroundColor: theme.palette.mode === "dark" ? "#1e1e1e" : "#ffffff",
                height: "300px", 
                overflowY: "auto", 
                borderRadius: "5px",
              }}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
            />
          </div>
          <Typography variant="caption" display="block" align="right" style={{ marginTop: "5px" }}>
            {justificativa.length}/{MAX_CHARACTERS} caracteres
          </Typography>
        </Grid>

       
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2} style={{ marginTop: "16px" }}>
            <Grid item>
              <Button variant="outlined" color="secondary" onClick={onClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Salvar Justificativa
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default JustificativaModal;

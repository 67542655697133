import axios from 'axios';
import { redirectToAuth } from "./AuthService";

// Carrega a URL correta do .env
const API_URL = process.env.REACT_APP_FLASK_BACKEND;

const apiRequest = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiRequest.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {
        redirectToAuth();
    }
    return Promise.reject(error);
});


export default apiRequest;
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  CircularProgress,
  Tooltip,
  Chip,
  List,
  ListItem,
  ListItemText,
  TextField,
  Autocomplete, 
  DialogActions,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import apiRequest from "../../service/apiRequest";
import { Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext } from "react";
import { AlertContext } from "../../Provider/AlertProvider";


const UserManagementModal = ({ open, onClose, projectId }) => {
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]); // Estado para Empresas
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // Add state for selected user
  const [roles, setRoles] = useState([]); // Lista de funções
  const [loadingMore, setLoadingMore] = useState(false);
  const { displaySuccessAlert, displayErrorAlert } = useContext(AlertContext);



  const API_BASE_URL = process.env.REACT_APP_FLASK_BACKEND_BEP;

useEffect(() => {
  if (open && projectId) {
    fetchUsers();
    fetchCompanies();
    fetchRoles(); // 🚀 Agora busca direto da nova rota
  }
}, [open, projectId]);

  // 🔹 Converte DD/MM/YYYY para YYYY-MM-DD
const convertToISOFormat = (dateString) => {
  console.log(`🔄 Convertendo data para ISO: ${dateString}`);

  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  const match = dateString.match(dateRegex);

  if (!match) {
    console.warn(`⚠️ Data inválida detectada: ${dateString}`);
    return null;
  }

  const [, day, month, year] = match;
  const isoDate = `${year}-${month}-${day}`;

  console.log(`✅ Data formatada corretamente para ISO: ${isoDate}`);
  return isoDate;
};


 const fetchUsers = async () => {
  setLoading(true);
  try {
    console.log("🔄 Buscando usuários do projeto...");

    const response = await apiRequest.get(`${API_BASE_URL}/users/list_project_users/${projectId}`);
    console.log("📢 Resposta da API (lista de usuários do projeto):", response.data);

    const projectUsers = response.data?.users || [];

    const dbResponse = await apiRequest.get(`${API_BASE_URL}/users/list_db_project_users/${projectId}`);
    console.log("📢 Resposta da API (banco de dados usuários):", dbResponse.data);

    const dbUsers = dbResponse.data?.users || [];

    const dbUserMap = dbUsers.reduce((acc, user) => {
      acc[user.email] = user;
      return acc;
    }, {});

    const updatedUsers = projectUsers.map((user) => {
      const dbUser = dbUserMap[user.email] || {};
      let expirationTimestamp = dbUser.expiration_timestamp || user.expiration_timestamp || null;

      console.log(`📌 Usuário: ${user.email}, Data recebida: ${expirationTimestamp}`);

      let formattedExpiration = "Sem expiração";

      if (expirationTimestamp) {
        // Removendo a hora, se presente
        if (expirationTimestamp.includes("T")) {
          expirationTimestamp = expirationTimestamp.split("T")[0]; 
        }

        // Garantindo que está no formato correto
        const [year, month, day] = expirationTimestamp.split("-");
        if (year && month && day) {
          formattedExpiration = `${day}/${month}/${year}`; 
        }
      }

      console.log(`✅ Usuário: ${user.email}, Data formatada: ${formattedExpiration}`);

      return {
        ...user,
        addedOn: dbUser.addedOn || user.addedOn || null,
        expiration_timestamp: formattedExpiration,
      };
    });

    console.log("📢 Lista de usuários processada:", updatedUsers);

    setUsers(updatedUsers);
  } catch (error) {
    console.error("❌ Erro ao buscar usuários:", error);
    setUsers([]);
  } finally {
    setLoading(false);
  }
};


  const updateUsers = async () => {
  if (!projectId || users.length === 0) {
    displayErrorAlert("Nenhum usuário para atualizar.");
    return;
  }

  setLoading(true); // Ativa o carregamento enquanto atualiza os usuários
  console.log("Iniciando atualização dos usuários...");

  for (const user of users) {
    const payload = {
      companyId: user.companyId,
      companyName: companies.find(c => c.id === user.companyId)?.name || "Desconhecido",
      roleIds: user.roles?.map(role => role.id) || [],
      accessLevels: {
        projectAdmin: user.accessLevels?.projectAdmin || false
      },
      expiration_timestamp: user.expiration_timestamp || null // 🔥 Adicionando expiração
    };

    try {
      console.log(`Atualizando usuário ${user.email}...`, payload);
      const url = `${API_BASE_URL}/users/update_project_user/${projectId}/${user.id}`;

      await apiRequest.patch(url, payload);
      displaySuccessAlert(`Usuário ${user.email} atualizado com sucesso!`);
    } catch (error) {
      console.error(`Erro ao atualizar usuário ${user.email}:`, error);
      displayErrorAlert(`Erro ao atualizar ${user.email}: ${error.response?.data?.error || "Erro desconhecido"}`);
    }
  }

  setLoading(false);
  console.log("Atualização concluída!");
};



  // 🔹 Função para buscar Empresas
  const fetchCompanies = async () => {
    try {
      const response = await apiRequest.get(`${API_BASE_URL}/list/companies`);

      if (response.data && Array.isArray(response.data.companies)) {
        console.log("Empresas carregadas:", response.data.companies); // Debug
        setCompanies(response.data.companies);
      } else {
        console.warn("Nenhuma empresa encontrada.");
        setCompanies([]); // Garante que nunca será undefined
      }
    } catch (error) {
      console.error("Erro ao buscar empresas:", error);
      setCompanies([]); // Evita erro na interface
    }
  };

  // 🔹 Função para buscar Funções (Roles)
 const fetchRoles = async () => {
  setLoadingMore(true);
  try {
    console.log("🔄 Buscando TODAS as funções...");
    const response = await apiRequest.get(`${API_BASE_URL}/list/project/users/roles`);

    console.log("📌 Resposta da API para roles:", response.data);

    if (!response.data || !Array.isArray(response.data) || response.data.length === 0) {
      console.warn("⚠️ Nenhuma função retornada pela API!");
      setRoles([]); // Garante que o estado roles não fique undefined
      return;
    }

    console.log("✅ Funções recebidas:", response.data);

    setRoles(response.data.sort((a, b) => a.name.localeCompare(b.name))); // Ordena antes de definir o estado

  } catch (error) {
    console.error("❌ Erro ao buscar funções:", error);
    setRoles([]); // Evita que o estado fique corrompido
  } finally {
    setLoadingMore(false);
  }
};



  // Ordenação personalizada para Funções (Roles)
  const sortedRoles = [...roles].sort((a, b) => a.name.localeCompare(b.name));

  const handleCompanyChange = (index, newValue) => {
    setUsers((prevUsers) =>
      prevUsers.map((u, i) => (i === index ? { ...u, companyId: newValue?.id || null } : u))
    );
  };

const RoleSelectionDialog = ({ open, onClose, roles, selectedRoles, setSelectedRoles, onSave }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 100;
  const [hasMore, setHasMore] = useState(true);

useEffect(() => {
  console.log("📢 Estado atualizado de roles:", roles);
}, [roles]);



  const fetchRoles = async () => {
  setLoading(true);
  try {
    console.log("🔄 Buscando TODAS as funções...");
    const response = await apiRequest.get(`${API_BASE_URL}/list/project/users/roles`);
    
    if (!response.data.roles || response.data.roles.length === 0) {
      console.warn("⚠️ Nenhuma função retornada pela API!");
      return;
    }

    console.log("✅ Funções recebidas:", response.data.roles);

    // Atualiza o estado com todas as funções, já ordenadas
    setRoles(
      [...response.data.roles]
        .sort((a, b) => a.name.localeCompare(b.name))
    );

  } catch (error) {
    console.error("❌ Erro ao buscar funções:", error);
  } finally {
    setLoading(false);
  }
};


  return (
   <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
  <DialogTitle>Selecionar Função</DialogTitle>
  <DialogContent>
    {loadingMore ? (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "200px" }}>
        <CircularProgress />
      </Box>
    ) : (
      <Autocomplete
        multiple
        options={
          roles.length > 0
            ? roles
                .sort((a, b) => {
                  const isSelectedA = selectedRoles.some((r) => r.id === a.id) ? -1 : 1;
                  const isSelectedB = selectedRoles.some((r) => r.id === b.id) ? -1 : 1;
                  return isSelectedA - isSelectedB || a.name.localeCompare(b.name);
                })
                .filter((role) => role.name.toLowerCase().includes(searchTerm.toLowerCase()))
            : []
        }
        getOptionLabel={(option) => option.name || "Função Desconhecida"}
        value={selectedRoles}
        onChange={(event, newValue) => setSelectedRoles(newValue)}
        inputValue={searchTerm}
        onInputChange={(event, newValue) => setSearchTerm(newValue)}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Buscar função..." fullWidth />
        )}
        renderOption={(props, option) =>
          option.id === "loading" ? (
            <Box {...props} sx={{ display: "flex", alignItems: "center", gap: 1, p: 2 }}>
              <Typography variant="body2">
                Carregando funções...
                <CircularProgress size={20} />
              </Typography>
            </Box>
          ) : (
            <li {...props}>{option.name}</li>
          )
        }
        renderTags={(value) =>
          value.length > 1 ? (
            <Chip label={`${value[0].name} +${value.length - 1}`} />
          ) : (
            value.map((option) => <Chip key={option.id} label={option.name} />)
          )
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    )}
  </DialogContent>

  <DialogActions>
    <Button onClick={onClose} color="secondary">
      Cancelar
    </Button>
    <Button onClick={() => onSave(selectedRoles)} color="primary">
      Salvar
    </Button>
  </DialogActions>
</Dialog>

  );
};

const RoleSelectionButton = ({ params, roles, setUsers }) => {
  const [open, setOpen] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState(params.row.roles || []);

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

const handleSave = async (userId, expiration) => {
  await updateExpiration(userId, expiration);
  await fetchUsers(); // 🔄 Recarrega os usuários do backend
  onClose();
};




  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          width: "100%",
          textTransform: "none",
          borderRadius: 0, 
          fontSize: "0.85rem",
          padding: "4px",
        }}
      >
        {selectedRoles.length > 1
          ? `${selectedRoles[0].name} +${selectedRoles.length - 1}`
          : selectedRoles.length === 1
          ? selectedRoles[0].name
          : "Nenhuma função atribuída"}
      </Button>

      <RoleSelectionDialog
        open={open}
        onClose={handleClose}
        roles={roles}
        selectedRoles={selectedRoles}
        setSelectedRoles={setSelectedRoles}
        onSave={handleSave}
      />
    </>
  );
};


  const CompanySelectionDialog = ({ open, onClose, companies, selectedCompany, setSelectedCompany, onSave }) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>Selecionar Empresa</DialogTitle>
    <DialogContent>
      <Typography variant="h6" style={{ marginTop: 16 }}>
        Empresa
      </Typography>
      
      <Autocomplete
        options={companies}
        getOptionLabel={(option) => option.name || "Empresa Desconhecida"}
        value={selectedCompany}
        onChange={(event, newValue) => setSelectedCompany(newValue)}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" fullWidth />
        )}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary">
        Cancelar
      </Button>
      <Button onClick={onSave} color="primary">
        Salvar
      </Button>
    </DialogActions>
  </Dialog>
);


 const CompanySelectionButton = ({ params, companies, setUsers }) => {
  const [open, setOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(
    companies.find((comp) => comp.id === params.row.companyId) || null
  );


  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSave = () => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === params.row.id ? { ...user, companyId: selectedCompany?.id } : user
      )
    );
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          width: "100%",
          textTransform: "none",
          borderRadius: 0, 
          fontSize: "0.85rem",
          padding: "4px",
        }}
      >
         {selectedCompany ? selectedCompany.name : (companies.length > 0 ? "Sem empresa" : "Carregando...")}
      </Button>

      <CompanySelectionDialog
        open={open}
        onClose={handleClose}
        companies={companies}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
        onSave={handleSave}
      />
    </>
  );
};


const AccessLevelSelectionDialog = ({ open, onClose, user, setUsers, onSave }) => {
  const [selectedAccessLevel, setSelectedAccessLevel] = useState(
    user.accessLevels?.projectAdmin ? "Administrador de Projeto" : "Membro de Projeto"
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Selecionar Nível de Acesso</DialogTitle>
      <DialogContent>
        <Typography variant="h6" style={{ marginTop: 16 }}>
          Nível de Acesso
        </Typography>

        <Autocomplete
          options={["Administrador de Projeto", "Membro de Projeto"]}
          value={selectedAccessLevel}
          onChange={(event, newValue) => setSelectedAccessLevel(newValue)}
          renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            setUsers((prevUsers) =>
              prevUsers.map((u) =>
                u.id === user.id
                  ? { ...u, accessLevels: { ...u.accessLevels, projectAdmin: selectedAccessLevel === "Administrador de Projeto" } }
                  : u
              )
            );
            onSave();
            onClose();
          }}
          color="primary"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AccessLevelSelectionButton = ({ params, setUsers }) => {
  const [open, setOpen] = useState(false);
  const user = params.row;
  const currentAccessLevel = user.accessLevels?.projectAdmin ? "Administrador de Projeto" : "Membro de Projeto";

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          width: "100%",
          textTransform: "none",
          borderRadius: 0,
          fontSize: "0.85rem",
          padding: "4px",
        }}
      >
        {currentAccessLevel}
      </Button>

      <AccessLevelSelectionDialog open={open} onClose={handleClose} user={user} setUsers={setUsers} />
    </>
  );
};

const updateExpiration = async (userId, newExpiration) => {
  try {
    console.log(`📢 Atualizando expiração do usuário ${userId}, data recebida: ${newExpiration}`);

    // Certifique-se de converter corretamente DD/MM/YYYY para YYYY-MM-DD
    let formattedDate = newExpiration;
    if (newExpiration.includes("/")) {
      const [day, month, year] = newExpiration.split("/");
      formattedDate = `${year}-${month}-${day}`; // Garante o formato ISO
    }

    console.log(`📌 Data convertida para formato ISO: ${formattedDate}`);

    if (!formattedDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
      displayErrorAlert("Formato de data inválido! Use DD/MM/YYYY.");
      return;
    }

    const url = `${API_BASE_URL}/users/update_project_user/${projectId}/${userId}`;
    const payload = { expiration_timestamp: formattedDate };

    console.log(`🚀 Enviando payload para API:`, payload);
    await apiRequest.patch(url, payload);

    setUsers((prevUsers) =>
      prevUsers.map((u) =>
        u.id === userId ? { ...u, expiration_timestamp: formattedDate } : u
      )
    );

    displaySuccessAlert("Expiração atualizada com sucesso!");
  } catch (error) {
    console.error(`❌ Erro ao atualizar expiração do usuário ${userId}:`, error);
    displayErrorAlert(`Erro ao atualizar expiração: ${error.response?.data?.error || "Erro desconhecido"}`);
  }
};



const ExpirationSelectionDialog = ({ open, onClose, user, setUsers, updateExpiration }) => {
  const [selectedExpiration, setSelectedExpiration] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // Estado para mensagem de erro

useEffect(() => {
  if (user.expiration_timestamp && user.expiration_timestamp !== "Validade Indefinida") {
    let rawDate = user.expiration_timestamp;

    // Se vier no formato ISO (YYYY-MM-DD), converte para DD/MM/YYYY
    if (rawDate.includes("-")) {
      const [year, month, day] = rawDate.split("-");
      rawDate = `${day}/${month}/${year}`;
    }

    setSelectedExpiration(rawDate);
  } else {
    setSelectedExpiration(""); // Evita exibir valores errados
  }
}, [user.expiration_timestamp]);



  // Função para formatar a entrada automaticamente enquanto o usuário digita
  const formatDate = (value) => {
    let cleaned = value.replace(/\D/g, ""); // Remove tudo que não for número
    let formatted = "";

    if (cleaned.length > 0) {
      formatted = cleaned.substring(0, 2);
    }
    if (cleaned.length > 2) {
      formatted += "/" + cleaned.substring(2, 4);
    }
    if (cleaned.length > 4) {
      formatted += "/" + cleaned.substring(4, 8);
    }

    return formatted;
  };

  // Valida a data inserida
  const isValidDate = (dateString) => {
    const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/; // Formato DD/MM/YYYY
    const match = dateString.match(dateRegex);
    if (!match) return false;

    const day = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1; // Meses começam do zero no JS
    const year = parseInt(match[3], 10);

    const date = new Date(year, month, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day
    );
  };

  // Manipula mudanças na data enquanto o usuário digita
  const handleChange = (event) => {
    let value = event.target.value;

    if (value.length === 0) {
      setSelectedExpiration("");
      return;
    }

    const formattedValue = formatDate(value);
    setSelectedExpiration(formattedValue);
    setErrorMessage(""); // Limpa erro enquanto o usuário digita
  };

  const handleSave = () => {
  if (!convertToISOFormat(selectedExpiration)) {
    setErrorMessage("Data inválida! Use o formato DD/MM/YYYY.");
    return;
  }

  setUsers((prevUsers) =>
    prevUsers.map((u) =>
      u.id === user.id ? { ...u, expiration_timestamp: selectedExpiration } : u
    )
  );
  updateExpiration(user.id, selectedExpiration);
  onClose();
};




  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Selecionar Expiração</DialogTitle>
      <DialogContent>
        <Typography variant="h6" style={{ marginTop: 16 }}>Data de Expiração</Typography>
        <TextField
        type="text"
        fullWidth
        value={selectedExpiration || ""}
        onChange={handleChange}
        placeholder="DD/MM/YYYY"
        error={!!errorMessage}
        helperText={errorMessage || "Exemplo: DD/MM/AAAA"}
        inputProps={{ maxLength: 10 }}
      />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancelar</Button>
        <Button onClick={handleSave} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ExpirationSelectionButton = ({ params, setUsers }) => {
  const [open, setOpen] = useState(false);
  const user = params.row;

  const rawValue = params.value; // Mantém como está vindo (YYYY-MM-DD ou DD/MM/YYYY)

  // Converte para exibição no botão
  let expirationDate = "Sem expiração";
  if (rawValue && rawValue.includes("-")) {
    const [year, month, day] = rawValue.split("-");
    expirationDate = `${day}/${month}/${year}`;
  } else if (rawValue && rawValue.includes("/")) {
    expirationDate = rawValue; // Já está formatada
  }

  const handleOpen = (event) => {
    event.stopPropagation();
    if (expirationDate !== "Sem expiração") {
      setOpen(true);
    }
  };

  return (
    <>
      <Tooltip
        title={expirationDate !== "Sem expiração" ? "Clique para editar a expiração" : "Não pode ser alterado"}
        arrow
      >
        <span>
          <Button
            variant="contained"
            onClick={handleOpen}
            disabled={expirationDate === "Sem expiração"}
            sx={{
              width: "100%",
              textTransform: "none",
              borderRadius: 0,
              fontSize: "0.85rem",
              padding: "4px",
              backgroundColor: expirationDate !== "Sem expiração" ? "#64b5f6" : "#bdbdbd",
              "&:hover": expirationDate !== "Sem expiração" ? { backgroundColor: "#42a5f5" } : {},
            }}
          >
            {expirationDate}
          </Button>
        </span>
      </Tooltip>

      <ExpirationSelectionDialog
        open={open}
        onClose={() => setOpen(false)}
        user={user}
        setUsers={setUsers}
        updateExpiration={updateExpiration}
      />
    </>
  );
};



const deleteUser = async (userId) => {
  const confirmDelete = window.confirm("🗑️ Tem certeza que deseja remover este usuário?");
  if (!confirmDelete) return;

  try {
    const url = `${API_BASE_URL}/users/delete_project_user/${projectId}/${userId}`;
    await apiRequest.delete(url);
    setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
    console.log("Usuário removido com sucesso!");
  } catch (error) {
    console.error(`Erro ao remover usuário ${userId}:`, error);
  }
};

 const columns = [
  { field: "email", headerName: "E-mail", width: 250 },

  {
    field: "companyId",
    headerName: "Empresa",
    width: 200,
    renderCell: (params) => (
      <CompanySelectionButton params={params} companies={companies} setUsers={setUsers} />
    ),
  },

  {
    field: "accessLevels",
    headerName: "Nível de Acesso",
    width: 250,
    renderCell: (params) => <AccessLevelSelectionButton params={params} setUsers={setUsers} />,
  },

  {
    field: "roles",
    headerName: "Função",
    width: 250,
    renderCell: (params) => (
      <RoleSelectionButton params={params} roles={roles} setUsers={setUsers} />
    ),
  },

  {
    field: "addedOn",
    headerName: "Adicionado em",
    width: 200,
    renderCell: (params) => {
      const rawDate = params.value;
      if (!rawDate) return "N/A";

      const date = new Date(rawDate);
      const formattedDate = date.toLocaleDateString("pt-BR");
      const formattedTime = date.toLocaleTimeString("pt-BR");

      return (
        <Tooltip title={`Hora: ${formattedTime}`} arrow>
          <span>{formattedDate}</span>
        </Tooltip>
      );
    },
  },

{
  field: "expiration_timestamp",
  headerName: "Expiração",
  width: 200,
  renderCell: (params) => (
    <ExpirationSelectionButton 
      params={params}
      setUsers={setUsers}
    />
  ),
},


  {
    field: "delete",
    headerName: "Remover",
    width: 100,
    renderCell: (params) => (
      <Tooltip title="Remover usuário">
        <IconButton onClick={() => deleteUser(params.row.id)} color="error">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    ),
  },
];


  return (
   <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
  <DialogTitle>
    Gerenciar Usuários
    <IconButton onClick={onClose} style={{ position: "absolute", right: 10, top: 10 }}>
      <CloseIcon />
    </IconButton>
  </DialogTitle>

  <DialogContent>
    {loading ? (
      // Centraliza o loading na tela
      <Box display="flex" justifyContent="center" alignItems="center" height="300px">
        <CircularProgress />
      </Box>
    ) : (
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={users}
          columns={columns}
          pageSize={5}
          getRowId={(row) => row.id || row.email}
          onRowClick={(params) => setSelectedUser(params.row)}
        />
      </Box>
    )}

   
   <Box
  display="flex"
  justifyContent="center"
  alignItems="center"
  flexDirection={{ xs: "column", sm: "row" }}
  gap={2} 
  mt={3}
  width="100%"
>
  <Button
    onClick={updateUsers}
    variant="contained"
    sx={{
      fontSize: "0.85rem",
      padding: "10px 24px",
      minWidth: "200px",
      backgroundColor: "#ba68c8",
      "&:hover": { backgroundColor: "#ab47bc" },
    }}
  >
    Atualizar Membros
  </Button>

  <Button
    onClick={onClose}
    variant="contained"
    sx={{
      fontSize: "0.85rem",
      padding: "10px 24px",
      minWidth: "200px",
      backgroundColor: "#64b5f6",
      "&:hover": { backgroundColor: "#42a5f5" },
    }}
  >
    Fechar
  </Button>
</Box>

  </DialogContent>
</Dialog>


  );
};

export default UserManagementModal;

import { createContext, useState, useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";

const defaultMessageTimeout = 2010;

export const AlertContext = createContext({});

export const AlertProvider = ({ children }) => {
    const [alertQueue, setAlertQueue] = useState([]); // Fila de mensagens
    const [currentAlert, setCurrentAlert] = useState(null);

    useEffect(() => {
        if (!currentAlert && alertQueue.length > 0) {
            // Pegar a primeira mensagem da fila
            setCurrentAlert(alertQueue[0]);

            // Remover a mensagem da fila após o timeout
            setTimeout(() => {
                setCurrentAlert(null);
                setAlertQueue((prevQueue) => prevQueue.slice(1)); // Remove o primeiro item
            }, defaultMessageTimeout);
        }
    }, [alertQueue, currentAlert]);

    function displaySuccessAlert(message) {
        setAlertQueue((prevQueue) => [...prevQueue, { type: "success", message }]);
    }

    function displayErrorAlert(message) {
        setAlertQueue((prevQueue) => [...prevQueue, { type: "error", message }]);
    }

    return (
        <AlertContext.Provider value={{ displaySuccessAlert, displayErrorAlert }}>
            {children}

            {/* Alerta com Snackbar e estilo original */}
            <Snackbar
                open={!!currentAlert}
                autoHideDuration={defaultMessageTimeout}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                {currentAlert && (
                    <Alert
                        severity={currentAlert.type}
                        variant="filled"
                    >
                        {currentAlert.message}
                    </Alert>
                )}
            </Snackbar>
        </AlertContext.Provider>
    );
};

import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  IconButton,
  Select,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AlertContext } from "../../Provider/AlertProvider";
import "dayjs/locale/pt-br";
import apiRequest from "../../service/apiRequest";
import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";


const EditProjectModal = ({ open, onClose, projectData, onUpdate }) => {
  const { displaySuccessAlert, displayErrorAlert } = useContext(AlertContext);
  const [formData, setFormData] = useState({});
  const [rawValorDoProjeto, setRawValorDoProjeto] = useState(""); // Guarda apenas o número puro
  const [loading, setLoading] = useState(false);
  const [adminEmails, setAdminEmails] = useState([]);
  const [loadingAdmins, setLoadingAdmins] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true); // ✅ Estado para garantir que os dados sejam carregados corretamente
  const [isModalLoading, setIsModalLoading] = useState(true); // ✅ Estado para o delay inicial
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const [inputValue, setInputValue] = useState("");
 

  // Opções fixas para os selects de Infraestrutura e Tipo de Projeto
  const infraOptions = ["Suprimento de água", "Águas residuais/esgoto","Serviços de utilidade pública"];
  const tipoProjetoOptions = [
    "Crescimento Vegetativo",
    "CT",  
    "Eimob - BASE",  
    "ID PPI_EPM - Expansão (água)",  
    "ID PPI_EPM - Renovação (esgoto)",  
    "ID PPI_EPR",  
    "Imobilizados",  
    "Obras_Modelo_PNM",  
    "EMQ - Modelo",  
    "EPP - Concepção",

  ];

useEffect(() => {
  if (!inputValue || !adminEmails.length) {
    setFilteredAdmins(adminEmails);
  } else {
    setFilteredAdmins(
      adminEmails.filter((user) => 
        user.email?.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  }
}, [inputValue, adminEmails]);



 useEffect(() => {
  if (open) {
    setIsModalLoading(true); // Ativa o loading inicial
    setTimeout(() => {
      setIsModalLoading(false); // Após 8 segundos, desativa o loading
    }, 2000);
  }
}, [open]);

useEffect(() => {
  if (!isModalLoading && open && projectData && Object.keys(projectData).length > 0) {
    setFormData({
      nomeProjeto: projectData.name || "",
      numeroProjeto: projectData.job_number || "",
      type: projectData.type || infraOptions[0],  // ✅ Garante um valor padrão
      tipo_projeto: projectData.tipo_projeto || tipoProjetoOptions[0], // ✅ Garante um valor padrão
      endereco: projectData.address_line1 || "",
      cidade: projectData.city || "",
      estado: projectData.state_or_province || "",
      cep: projectData.postal_code || "",
      status: projectData.status || "",
      data_inicio: projectData.start_date ? dayjs(projectData.start_date) : null,
      data_fim: projectData.end_date ? dayjs(projectData.end_date) : null,
      valor_do_projeto: projectData.valor_do_projeto 
        ? new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(parseFloat(projectData.valor_do_projeto)) // Exibir formatado
        : "",
      admin_email: projectData.admin_email || "",
    });

    // Define o valor puro (float) para o envio
    setRawValorDoProjeto(projectData.valor_do_projeto ? parseFloat(projectData.valor_do_projeto) : "");

    setIsLoadingData(false); // ✅ Marca os dados como carregados
    fetchAdminEmails();
  }
}, [open, projectData, isModalLoading]);

useEffect(() => {
  if (open) {
    fetchAdminEmails(); // Fetch admin emails immediately when the modal opens
  }
}, [open]);

useEffect(() => {
  if (!loadingAdmins && projectData?.admin_email) {
    if (!adminEmails.includes(projectData.admin_email)) {
      setAdminEmails((prev) => [...prev, projectData.admin_email]);
    }
    setFormData((prev) => ({
      ...prev,
      admin_email: projectData.admin_email,
    }));
  }
}, [adminEmails, loadingAdmins, projectData?.admin_email]);

  // Buscar lista de administradores do projeto
const fetchAdminEmails = async () => {
  try {
    setLoadingAdmins(true);
    const response = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/list-project_admin`);

    if (response.data && Array.isArray(response.data.admin_emails)) {
      const formattedEmails = response.data.admin_emails.map((email) => ({ email }));
      setAdminEmails(formattedEmails);
      setFilteredAdmins(formattedEmails);
    } else {
      displayErrorAlert("Formato inesperado da resposta.");
    }
  } catch (error) {
    displayErrorAlert("Erro ao carregar lista de administradores.");
  } finally {
    setLoadingAdmins(false);
  }
};



  const handleAdminClick = () => {
    if (adminEmails.length === 0) {
      fetchAdminEmails();
    }
  };

  const handleDateChange = (name, value) => {
    setFormData({ ...formData, [name]: value ? dayjs(value) : null });
  };

const MAX_VALUE = 999999999.99; // Valor máximo permitido

const handleChange = (e) => {
  const { name, value } = e.target;

  if (name === "valor_do_projeto") {
    // Remove tudo que não for número
    let numericValue = value.replace(/\D/g, ""); 

    // Converte para número decimal (dividindo por 100 para manter 2 casas decimais)
    let floatValue = Number(numericValue) / 100;

    // Se ultrapassar o limite, mantém o máximo permitido
    if (floatValue > MAX_VALUE) {
      floatValue = MAX_VALUE;
    }

    // Aplica a formatação brasileira (R$ 1.234.567,89)
    let formattedValue = floatValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    // Atualiza o estado formatado e o valor numérico puro
    setFormData({ ...formData, [name]: formattedValue });
    setRawValorDoProjeto(floatValue); // Guarda apenas o número puro para envio ao backend
  } else {
    setFormData({ ...formData, [name]: value });
  }
};


  const handleUpdateProject = async () => {
  setLoading(true);

  if (formData.data_inicio && formData.data_fim) {
    if (formData.data_fim.isBefore(formData.data_inicio, "day")) {
      displayErrorAlert("A data de término não pode ser anterior à data de início.");
      setLoading(false);
      return;
    }
  }

  const requiredFields = {
    nomeProjeto: "Nome do Projeto",
    numeroProjeto: "Número do Projeto",
    type: "Infraestrutura",
    tipo_projeto: "Tipo de Projeto",
    data_inicio: "Data de Início",
    data_fim: "Data de Término",
    cidade: "Cidade",
    estado: "Estado",
    valor_do_projeto: "Valor do Projeto",
    admin_email: "E-mail do Administrador",
  };

  for (const [key, label] of Object.entries(requiredFields)) {
    if (!formData[key] || formData[key].toString().trim() === "") {
      displayErrorAlert(`O campo "${label}" é obrigatório.`);
      setLoading(false);
      return;
    }
  }

  // Convertendo data e garantindo que `valor_do_projeto` seja enviado como número puro
  const formattedData = {
    ...formData,
    data_inicio: formData.data_inicio ? formData.data_inicio.format("YYYY-MM-DD") : null,
    data_fim: formData.data_fim ? formData.data_fim.format("YYYY-MM-DD") : null,
    valor_do_projeto: rawValorDoProjeto, // Envia o valor puro sem formatação
  };

  try {
    await apiRequest.put(
      `${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/${projectData.id}/update`,
      formattedData
    );

    // Buscar os dados atualizados do projeto antes de fechar o modal
    const updatedProjectResponse = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/${projectData.id}`);
    
    displaySuccessAlert("Projeto atualizado com sucesso!");
    
    onUpdate(updatedProjectResponse.data); // Atualiza os dados no estado do pai
    onClose();
  } catch (error) {
    displayErrorAlert("Erro ao atualizar o projeto.");
  } finally {
    setLoading(false);
  }
};
const handleClose = (event, reason) => {
  if (reason === "backdropClick") {
    return; // Impede o fechamento ao clicar fora do modal
  }
  onClose(); // Fecha apenas quando o usuário clica no botão "Cancelar" ou "Fechar"
};


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <Dialog 
        open={open} 
        onClose={(event, reason) => handleClose(event, reason)} 
        maxWidth="sm" 
        fullWidth
      >

        <Grid container justifyContent="space-between" alignItems="center" style={{ padding: "16px" }}>
          <Typography variant="h6">Editar Projeto</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Grid>

        <DialogContent>
          {isModalLoading ? ( // ✅ Exibir um loading de 5 segundos antes de mostrar o conteúdo
            <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "200px" }}>
              <CircularProgress />
              <Typography variant="body2" style={{ marginLeft: "10px" }}>Carregando dados...</Typography>
            </Grid>
          ) : isLoadingData ? ( // ✅ Exibir um carregamento enquanto os dados ainda não foram carregados
            <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "200px" }}>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField label="Nome do Projeto" name="nomeProjeto" value={formData.nomeProjeto} onChange={handleChange} fullWidth required />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Número do Projeto" name="numeroProjeto" value={formData.numeroProjeto} onChange={handleChange} fullWidth required />
              </Grid>
              <Grid item xs={12}>
                <TextField select label="Infraestrutura" name="type" value={formData.type} onChange={handleChange} fullWidth required>
                  {infraOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField select label="Tipo de Projeto" name="tipo_projeto" value={formData.tipo_projeto} onChange={handleChange} fullWidth required>
                  {tipoProjetoOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField label="Endereço" name="endereco" value={formData.endereco} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Cidade" name="cidade" value={formData.cidade} onChange={handleChange} fullWidth required />
              </Grid>
              <Grid item xs={3}>
                <TextField label="Estado" name="estado" value={formData.estado} onChange={handleChange} fullWidth required />
              </Grid>
              <Grid item xs={3}>
                <TextField label="CEP" name="cep" value={formData.cep} onChange={handleChange} fullWidth  />
              </Grid>
              <Grid item xs={6}>
                <DatePicker label="Data de Início" value={formData.data_inicio} onChange={(value) => handleDateChange("data_inicio", value)} format="DD/MM/YYYY" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <DatePicker label="Data de Término" value={formData.data_fim} onChange={(value) => handleDateChange("data_fim", value)} format="DD/MM/YYYY" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Valor do Projeto (BRL)" name="valor_do_projeto" value={formData.valor_do_projeto} onChange={handleChange} fullWidth required />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Status" name="status" value={formData.status} fullWidth disabled />
              </Grid>
              <Grid item xs={12}>
              {!adminEmails.length && !loadingAdmins && (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={fetchAdminEmails}
                >
                  Carregar Administradores
                </Button>
              )}

              {loadingAdmins && (
                <Button
                  variant="outlined"
                  fullWidth
                  disabled
                  startIcon={<CircularProgress size={20} />}
                >
                  Carregando administradores de projeto...
                </Button>
              )}

              {!loadingAdmins && adminEmails.length > 0 && (
                <Autocomplete
                  options={filteredAdmins}
                  getOptionLabel={(option) => option.email || ""}
                  value={
                    adminEmails.find((user) => user.email === formData.admin_email) || null
                  }
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      admin_email: newValue ? newValue.email : "",
                    });
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Administrador de Projeto"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              )}
            </Grid>


            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={onClose}>Cancelar</Button>
          <Button variant="contained" color="primary" onClick={handleUpdateProject} disabled={loading}>{loading ? "Atualizando..." : "Atualizar"}</Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default EditProjectModal;

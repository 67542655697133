import React from "react";
import { useContext } from "react";
import { ProjectContext } from "../../Provider/ProjectProvider";
import FormButtonMenu from "./ButtonMenu";
import WaterLight from "../../assets/images/home/water-light.png";
import WaterDark from "../../assets/images/home/water-dark.png";
import SewageLight from "../../assets/images/home/sewage-light.png";
import SewageDark from "../../assets/images/home/sewage-dark.png";
import TechnicalCollectionDark from "../../assets/images/home/technical_collection-dark.png";
import TechnicalCollectionLight from "../../assets/images/home/technical_collection-light.png";
import StandardizationDark from "../../assets/images/home/standardization-dark.png";
import StandardizationLight from "../../assets/images/home/standardization-light.png";
import BookletSignosDark from "../../assets/images/home/booklet_signos-dark.png";
import BookletSignosLight from "../../assets/images/home/booklet_signos-light.png";
import BepDark from "../../assets/images/home/projeto-dark.png";
import BepLight from "../../assets/images/home/projeto-light.png";
import GeoBimDark from "../../assets/images/home/globo-dark.png";
import GeoBimLight from "../../assets/images/home/globo-light.png";
import BibliotecaDark from "../../assets/images/home/biblioteca-dark.png";
import BibliotecaLight from "../../assets/images/home/biblioteca-light.png";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../../Provider/AlertProvider";
import { Alert, Snackbar, useTheme } from "@mui/material";
import { AecFolderContext } from "../../Provider/AecFolderProvider";
import { AuthContext } from "../../Provider/AuthProvider";

const formSidebarStyle = {
  position: "fixed",
  left: "-7px",
  top: "-9px",
  height: "100vh",
  width: 50,
  display: "flex",
  justifyContent: "space-evenly",
  flexDirection: "column",
  gap: 10,
};

const FormSidebar = () => {
  const { Projects, selectedProject } = useContext(ProjectContext);
  const { clearFolderContents, projectsPermissions } =
    useContext(AecFolderContext);
  const { errorMessage, showErrorAlert, displayErrorAlert } =
    useContext(AlertContext);
  const { verifyToken } = useContext(AuthContext);

  let navigate = useNavigate();
  const theme = useTheme();

  // Funções de navegação
  const goToAgua = () => {
    verifyToken();
    if (!projectsPermissions.includes(Projects.AGUA)) {
      displayErrorAlert(
        "Você não possui permissão para acessar o projeto selecionado."
      );
    } else {
      let path = `/agua`;
      navigate(path);
      clearFolderContents();
    }
  };

  const goToEsgoto = () => {
    verifyToken();
    if (!projectsPermissions.includes(Projects.ESGOTO)) {
      displayErrorAlert(
        "Você não possui permissão para acessar o projeto selecionado."
      );
    } else {
      let path = `/esgoto`;
      navigate(path);
      clearFolderContents();
    }
  };

  const goToArquivoTecnico = () => {
    verifyToken();
    if (!projectsPermissions.includes(Projects.ARQUIVO)) {
      displayErrorAlert(
        "Você não possui permissão para acessar o projeto selecionado."
      );
    } else {
      let path = `/arquivoTecnico`;
      navigate(path);
    }
  };

  const goToTecBIM = () => {
    verifyToken();
    if (!projectsPermissions.includes(Projects.BEP)) {
      displayErrorAlert("Em desenvolvimento");
    }
  };

  const goToSignos = () => {
    verifyToken();
    if (!projectsPermissions.includes(Projects.SIGNOS)) {
      displayErrorAlert(
        "Você não possui permissão para acessar o projeto selecionado."
      );
    } else {
      const url =
        "https://acc.autodesk.com/docs/files/projects/f72fd221-7c8b-44c7-9ff0-6e941fe3cfd3?folderUrn=urn%3Aadsk.wipprod%3Afs.folder%3Aco.32onf_RDR_6uNgoyj__NAw&viewModel=detail&moduleId=folders";
      window.open(url, "_blank");
    }
  };

  const goToBep = () => {
    verifyToken();
    navigate("/bep");
  };


  const goToGeoBim = () => {
    verifyToken();
    if (!projectsPermissions.includes(Projects.BEP)) {
      displayErrorAlert("Em desenvolvimento");
    }
  };

  const goToBiblioteca = () => {
    verifyToken();
    if (!projectsPermissions.includes(Projects.TECBIM)) {
      displayErrorAlert("Você não possui permissão para acessar a biblioteca.");
    } else {
      const url =
        "https://acc.autodesk.com/docs/share/projects/1804abc4-b91b-4296-b200-f2945d8028b3/files?shareGroupId=946e9899-447d-4dba-b11c-6a788fa4c78d";
      window.open(url, "_blank");
    }
  };

  return (
    <div style={formSidebarStyle}>
      <FormButtonMenu
        isSelected={selectedProject === Projects.AGUA}
        onClick={goToAgua}
        text={"Água"}
        color={"#4287f5"}
        image={theme.palette.mode === "dark" ? WaterLight : WaterDark}
      />
      <FormButtonMenu
        isSelected={selectedProject === Projects.ESGOTO}
        onClick={goToEsgoto}
        text={"Esgoto"}
        color={"#F4B942"}
        image={theme.palette.mode === "dark" ? SewageLight : SewageDark}
      />
      <FormButtonMenu
        isSelected={selectedProject === Projects.ARQUIVO}
        onClick={goToArquivoTecnico}
        text={"SAT"}
        color={"#06d6a0"}
        image={
          theme.palette.mode === "dark"
            ? TechnicalCollectionLight
            : TechnicalCollectionDark
        }
      />
      <FormButtonMenu
        isSelected={selectedProject === Projects.TECBIM}
        onClick={goToTecBIM}
        text={"TecBIM"}
        color={"#b5a2cd"}
        image={
          theme.palette.mode === "dark"
            ? StandardizationLight
            : StandardizationDark
        }
      />
      <FormButtonMenu
        isSelected={selectedProject === Projects.SIGNOS}
        onClick={goToSignos}
        text={"Signos"}
        color={"#f45b69"}
        image={
          theme.palette.mode === "dark" ? BookletSignosLight : BookletSignosDark
        }
      />
      <FormButtonMenu
        isSelected={selectedProject === Projects.PROJETO}
        onClick={goToBep}
        text={"BEP"}
        color={"#7fff7f"}
        image={theme.palette.mode === "dark" ? BepLight : BepDark}
      />
      <FormButtonMenu
        isSelected={selectedProject === Projects.GLOBO}
        onClick={goToGeoBim}
        text={"GeoBIM"}
        color={"#705714"}
        image={theme.palette.mode === "dark" ? GeoBimLight : GeoBimDark}
      />
      <FormButtonMenu
        isSelected={false} // Não há lógica de seleção implementada para este item
        onClick={goToBiblioteca}
        text={"Biblioteca"}
        color={"#FF1493"}
        image={theme.palette.mode === "dark" ? BibliotecaLight : BibliotecaDark}
      />
      <Snackbar
        open={showErrorAlert}
        sx={{ height: "100%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </div>
  );
};

export default FormSidebar;

import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Card, Typography, Button, Grid, IconButton, Divider, Alert, CircularProgress, Backdrop, DataTable } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { format } from "date-fns";
import Header from "../../components/Header";
import Footer from "../../containers/footer";
import apiRequest from "../../service/apiRequest";
import { AlertContext } from "../../Provider/AlertProvider"; 
import { AecFolderContext } from "../../Provider/AecFolderProvider"; // Pegar o email do usuário logado
import MotivoRejeicaoModal from "../../components/Form/MotivoRejeicaoModal"; // Import the modal component
import EditProjectModal from "../../components/Form/EditProjectModal";
import { parse, isValid } from "date-fns";
const ProjectDetails = () => {
  const { id } = useParams(); 
  const navigate = useNavigate(); 
  const { userData } = useContext(AecFolderContext); // Obter o usuário logado
  const { displayErrorAlert, displaySuccessAlert } = useContext(AlertContext); 
  const [project, setProject] = useState(null); 
  const [approverEmail, setApproverEmail] = useState(""); // Armazena o e-mail do Administrador do Hub
  const [loading, setLoading] = useState(false); 
  const [actionMessage, setActionMessage] = useState(""); 
  const [showMotivoRejeicaoModal, setShowMotivoRejeicaoModal] = useState(false);
  const [motivoRejeicao, setMotivoRejeicao] = useState("");
  const [rejectionMessage, setRejectionMessage] = useState("");
  const location = useLocation();
  const updateProjectStatus = location.state?.updateProjectStatus || (() => {}); //Obtém função ou usa um fallback
  const [showEditModal, setShowEditModal] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const userRole = localStorage.getItem("userRole") || null;
  const isAccountAdmin = userRole === "account_admin";

  const [approvers, setApprovers] = useState([]); // Lista de aprovadores
  const [isUserApprover, setIsUserApprover] = useState(false); // Verifica se o usuário pode aprovar/rejeitar

  // Buscar detalhes do projeto e e-mail do Administrador do Hub
  useEffect(() => {
    const fetchProjectAndApprovers = async () => {
      try {
        // Buscar os detalhes do projeto
        const projectResponse = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/${id}`);
        setProject(projectResponse.data);

        // Buscar a lista de aprovadores
        const approversResponse = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/approvers/list`);	
        const approverEmails = approversResponse.data.map(a => a.email);
        setApprovers(approverEmails);

      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        displayErrorAlert("Erro ao carregar os detalhes do projeto.");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectAndApprovers();
  }, [id]);

  // Buscar lista de aprovadores e verificar se o usuário tem permissão
  useEffect(() => {
    const fetchApprovers = async () => {
      try {
        //Buscar lista de aprovadores do backend
        const response = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/approvers/list`);	
        const approverEmails = response.data.map(a => a.email);
        
        setApprovers(approverEmails);
  
        //  Verifica se o usuário logado faz parte da lista de aprovadores
        if (userData?.email) {
          setIsUserApprover(approverEmails.includes(userData.email));
        }
      } catch (error) {
        console.error("Erro ao buscar lista de aprovadores:", error);
        displayErrorAlert("Erro ao carregar a lista de aprovadores.");
      }
    };
  
    fetchApprovers();
  }, [userData?.email]); // Executa quando o e-mail do usuário está disponível

  // Sempre que a lista de aprovadores mudar, verificar se o usuário ainda tem permissão
  useEffect(() => {
    if (userData?.email) {
      setIsUserApprover(approvers.includes(userData.email));
    }
  }, [userData?.email, approvers]);

  // Validar se o usuário logado é o Administrador do Hub
  const isApprover = userData?.email === approverEmail;

  // Função para Aceitar projeto
 const handleApprove = async () => {
  if (!isUserApprover) {
    displayErrorAlert("Ação não permitida. Somente aprovadores podem aceitar ou recusar este projeto.");
    return;
  }

  setLoading(true);
  try {
    const response = await apiRequest.post(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/${id}/approve`, {
      user_email: userData?.email || "", // Certifique-se de que o email está sendo enviado
    });

    if (response.status === 200) {
      setActionMessage("Aprovado!");
      displaySuccessAlert(response.data.message);

      // FORÇAR ATUALIZAÇÃO BUSCANDO NOVOS DADOS DO PROJETO
      const updatedProject = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/${id}`);
      setProject(updatedProject.data);  // Atualiza os dados na tela
    }
  } catch (error) {
    console.error("Erro ao aceitar o projeto:", error);

    if (error.response) {
      console.error("Detalhes do erro:", error.response.data);
      displayErrorAlert(`Erro ao aprovar o projeto: ${error.response.data.error || "Erro desconhecido"}`);
    } else {
      displayErrorAlert("Erro ao aprovar o projeto.");
    }
  } finally {
    setLoading(false);
  }
};

  // Função para Reprovar projeto
  const handleReject = async (motivo) => {
    if (!motivo.trim()) {
      displayErrorAlert("Por favor, preencha o motivo da rejeição antes de enviar.");
      return;
    }

    setLoading(true);
    try {
      const response = await apiRequest.post(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/${id}/reject`, {
        motivo_rejeicao: motivo,
      });

      if (response.status === 200) {
        setProject((prev) => ({ ...prev, status: "Reprovado" }));
        setRejectionMessage(motivo); // Exibe o motivo da rejeição
        updateProjectStatus(id, "Reprovado", motivo); // Atualiza a lista global de projetos
        displaySuccessAlert("Projeto reprovado com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao recusar o projeto:", error);
      displayErrorAlert("Erro ao recusar o projeto.");
    } finally {
      setLoading(false);
    }
  };

  // Função para mascarar dados sensíveis
  const maskData = (data) => (isAccountAdmin ? data : "********");

  const formatJustificativa = (justificativa) => {
    if (!justificativa) return "N/A";
    
    // Converter quebras de linha corretamente e preservar parágrafos
    return justificativa
      .replace(/<p>/g, '<p style="margin-bottom: 10px;">') // Adiciona espaço entre parágrafos
      .replace(/<br>/g, '<br/>'); // Garante que os <br> sejam mantidos corretamente
  };

  const checkIfUserIsApprover = async () => {
    try {
      const response = await apiRequest.get(`${process.env.REACT_APP_FLASK_BACKEND_BEP}/project/approvers/list`);	
      const approverEmails = response.data.map(a => a.email);
      return approverEmails.includes(userData?.email);
    } catch (error) {
      console.error("Erro ao verificar a lista de aprovadores:", error);
      return false; // Em caso de erro, assume que o usuário não tem permissão
    }
  };

  const handleEditProject = () => {
    setProjectData({ ...project }); // Garante que os dados sejam passados corretamente
    setShowEditModal(true);
  };

  
  // Spinner de carregamento
  if (!project) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }}>
        <CircularProgress />
      </div>
    );
  }
const formatDateSafe = (dateStr) => {
  if (!dateStr || dateStr === "********") return "N/A";

  try {
    // Usa date-fns para parsear corretamente esse formato do backend
    const parsed = parse(dateStr, "EEE, dd MMM yyyy HH:mm:ss 'GMT'", new Date());

    if (!isValid(parsed)) {
      console.warn("Data inválida recebida:", dateStr);
      return "N/A";
    }

    return format(parsed, "dd/MM/yyyy");
  } catch (err) {
    console.warn("Erro ao formatar data:", err);
    return "N/A";
  }
};




  return (
    <>
      <Header />
      <main style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }}>
        <Card style={{ padding: "24px", maxWidth: "600px", width: "100%" }}>
          <IconButton onClick={() => navigate(-1)} aria-label="voltar">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" gutterBottom align="center">
            Detalhes do Projeto
          </Typography>
          <Divider />
          <Typography variant="body1"><strong>ID:</strong> {maskData(project.id)}</Typography>
          <Divider />
          <Typography variant="body1"><strong>Nome:</strong> {maskData(project.name) || "N/A"}</Typography>
          <Divider />
          <Typography variant="body1"><strong>Número do Projeto:</strong> {maskData(project.job_number) || "N/A"}</Typography>
          <Typography variant="body1"><strong>Endereço:</strong> {maskData(project.address_line1) || "N/A"}</Typography>
          <Divider />
          <Typography variant="body1"><strong>Cidade:</strong> {maskData(project.city) || "N/A"}</Typography>
          <Divider />
          <Typography variant="body1"><strong>Estado:</strong> {maskData(project.state_or_province) || "N/A"}</Typography>
          <Divider />
          <Typography variant="body1"><strong>CEP:</strong> {maskData(project.postal_code) || "N/A"}</Typography>
          <Divider />
          <Typography variant="body1"><strong>Status:</strong> {maskData(project.status) || "N/A"}</Typography>
          <Divider />
          <Typography variant="body1"><strong>Data de Início:</strong> {isAccountAdmin ? formatDateSafe(project.start_date) : "********"}</Typography>
          <Divider />
          <Typography variant="body1"><strong>Data de Término:</strong> {isAccountAdmin ? formatDateSafe(project.end_date) : "********"}</Typography>
          <Divider />
          <Typography variant="body1"><strong>Infraestrutura:</strong> {maskData(project.type) || "N/A"}</Typography>
          <Divider />
          <Divider />
          <Typography variant="body1"><strong>Tipo de Projeto:</strong> {maskData(project.tipo_projeto) || "N/A"}</Typography>
          <Divider />
          <Typography variant="body1">
          <strong>Valor do Projeto:</strong> {isAccountAdmin ? 
            new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(project.valor_do_projeto) 
            : "********"}
          </Typography>
          <Divider />
          <Typography variant="body1"><strong>Solicitante:</strong> {maskData(project.created_by_email) || "N/A"}</Typography>
          <Divider />
          <Divider />
          <Typography variant="body1"><strong>Administrador do Projeto:</strong> {maskData(project.admin_email) || "N/A"}</Typography>
          <Divider />
            <Divider />
            <Typography variant="body1"><strong>Avaliador:</strong> {maskData(project.responsavel_decisao) || "N/A"}</Typography>
          <Divider />
            <Divider />
          {/* Exibir o link da pasta somente se o projeto estiver aprovado */}
          {project.status === "Aprovado" && project.link_folder && (
            <>
              <Divider />
              <Typography variant="body1">
            <strong>Link da Pasta do Projeto:</strong>{" "}
            {isAccountAdmin ? (
              <a
                href={project.link_folder}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#007bff", textDecoration: "underline", wordWrap: "break-word" }}
              >
                Acessar Pasta do Projeto
              </a>
            ) : (
              "********"
            )}
          </Typography>

            </>
          )}
          <Divider />
          <Divider />
          <Typography variant="body1"><strong>Justificativa:</strong></Typography>
          <div 
            style={{
              backgroundColor: "transparent", // Mantém fundo transparente para evitar interferência
              color: "inherit", // Usa a cor herdada do tema
              padding: "10px",
              borderRadius: "5px",
              lineHeight: "1.5",
              border: "1px solid rgba(255, 255, 255, 0.1)", // Adiciona uma borda sutil no modo escuro
              whiteSpace: "pre-wrap", //Mantém as quebras de linha sem converter para `<p>` ou `<br>`
              wordWrap: "break-word"
            }}
            dangerouslySetInnerHTML={{ 
              __html: 
                isAccountAdmin || project.status !== "Reprovado"
                  ? project.justificativa 
                    ? project.justificativa.replace(/\n/g, "<br/>") 
                    : "N/A"
                  : "********"
            }} 
          />
        

          {/* Exibir mensagem apropriada para cada status */}
          {project.status === "Aprovado" && (
            <Alert severity="success" style={{ marginTop: "16px", textAlign: "center" }}>
              Aprovado!
            </Alert>
          )}
          {project.status === "Reprovado" && (
            <Alert severity="error" style={{ marginTop: "16px", textAlign: "center" }}>
              Reprovado!
            </Alert>
          )}
          {/* Exibir o motivo da rejeição após envio */}
          {project.status === "Reprovado" && project.motivo_rejeicao && isApprover && (
            <Alert severity="error" style={{ marginTop: "16px", textAlign: "left" }}>
              <strong>Motivo da Rejeição:</strong>
              <div 
                style={{
                  backgroundColor: "transparent",
                  color: "inherit",
                  padding: "10px",
                  borderRadius: "5px",
                  lineHeight: "1.5",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word"
                }}
                dangerouslySetInnerHTML={{ __html: project.motivo_rejeicao.replace(/<p>/g, "").replace(/<\/p>/g, "<br>") }}
              />
            </Alert>
          )}
          {/* Se o usuário logado não for o Administrador do Hub, exibir aviso de restrição */}
          {!isAccountAdmin && (
            <Alert severity="error" style={{ marginTop: "16px", textAlign: "center" }}>
              <strong>Acesso Restrito:</strong> Somente <strong>Administradores de Hub</strong> podem visualizar os detalhes completos do projeto.
            </Alert>
          )}


         {/* Ocultar os botões se o status for Aprovado ou Reprovado */}
          {isUserApprover && project.status === "Pendente" && (
            <Grid container spacing={2} justifyContent="center" style={{ marginTop: "24px" }}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEditProject}
                  disabled={loading}
                >
                  Editar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleApprove}
                  disabled={!isUserApprover || loading}
                >
                  Aceitar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setShowMotivoRejeicaoModal(true)}
                  disabled={!isUserApprover || loading}
                >
                  Recusar
                </Button>
              </Grid>
            </Grid>
          )}

        </Card>
      </main>
      <Footer />
      {/* Modal de Motivo de Rejeição */}
      <MotivoRejeicaoModal 
        open={showMotivoRejeicaoModal} 
        onClose={() => setShowMotivoRejeicaoModal(false)} 
        projectId={id}
        onRejectSuccess={(motivo) => {
          setProject((prev) => ({ ...prev, status: "Reprovado" }));
          setRejectionMessage(motivo);
          updateProjectStatus(id, "Reprovado", motivo); // Atualiza lista ao recusar
          setShowMotivoRejeicaoModal(false);
        }} 
      />
      <EditProjectModal
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        projectData={projectData}
        onUpdate={(updatedProject) => setProject(updatedProject)}
      />
      <Backdrop open={loading} style={{ zIndex: 1301, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ProjectDetails;

import { useContext, useEffect } from "react";
import { AuthContext } from "./Provider/AuthProvider";
import ReactLoading from "react-loading";
import { LoadingContext } from "./Provider/LoadingProvider";
import Router from "./screens/router";
import React from "react";
import { ColorModeContext } from "./Provider/ColorModeProvider";

function App() {
    const { verifyToken } = useContext(AuthContext);
    const { getPreviousSelectedTheme } = useContext(ColorModeContext);
    const { loading } = useContext(LoadingContext);

    useEffect(() => {
        verifyToken();
        getPreviousSelectedTheme();
       

    }, []);

    return (
        <>
            <div
                id="loading-container"
                className="loading"
                style={loading ? {} : { display: "none" }}
            >
                <ReactLoading
                    className="loading"
                    type={"spokes"}
                    color="#eefaff"
                />
            </div>
            <Router />
        </>
    );
}

export default App;
